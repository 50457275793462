import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import moment from "moment"
import { Helmet } from "react-helmet"
import { Row, Col } from "react-bootstrap"

import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import BlogThumbnail from "quorum/static/frontend/marketing-website/components/BlogThumbnail"
import EmailRequest from "quorum/static/frontend/marketing-website/components/EmailRequest"
import SocialMediaSidebar from "quorum/static/frontend/marketing-website/components/SocialMediaSidebar"

import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"

import {
    stripHtml,
    getPreviewFromHtmlContent,
    metadataTitle,
    embedTweets,
} from "quorum/static/frontend/marketing-website/constants/functions"

import "quorum/static/frontend/marketing-website/stylus/BlogDetail.styl"

const mapStateToProps = (state, props) => ({
    postsId: selectors.makeBlogIndividualSelector("id")(state),
    title: selectors.makeBlogIndividualSelector("title")(state),
    subtitle: selectors.makeBlogIndividualSelector("subtitle")(state),
    description: selectors.makeBlogIndividualSelector("description")(state),
    content: selectors.makeBlogIndividualSelector("content")(state),
    hubspot_cta: selectors.makeBlogIndividualSelector("hubspot_cta")(state),
    featured_image: selectors.makeBlogIndividualSelector("featured_image")(state),
    author: selectors.makeBlogIndividualSelector("author")(state),
    date: selectors.makeBlogIndividualSelector("date")(state),
    email: selectors.makeBlogIndividualSelector("email")(state),
    shouldUseFormHeader: selectors.makeBlogIndividualSelector("should_use_form_header")(state),
    ctaButtonText: selectors.makeBlogIndividualSelector("cta_button_text")(state),
    formHeader: selectors.makeBlogIndividualSelector("form_header")(state),
    gatedContentUrl: selectors.makeBlogIndividualSelector("gated_content_url")(state),
    slug: selectors.makeBlogIndividualSelector("slug")(state),
    whitePaperLink: selectors.makeBlogIndividualSelector("white_paper")(state),
    posts: selectors.makeBlogSliceSelector("posts")(state),
})

class BlogDetail extends React.Component {
    constructor(props) {
        super(props)
        this.loadBlogPost = this.loadBlogPost.bind(this)
    }

    componentDidMount() {
        this.loadBlogPost(this.props.params.id)

        // Iframe elements do not allow a click handler on them
        // Adding a blur event handler on the window, and checking if the active element is an Iframe
        window.addEventListener("blur", this.sendVideoEventTracking)
    }

    componentDidUpdate(prevProps) {
        embedTweets()

        // Only hit this conditional when clicking on related content
        if (prevProps.params.id !== this.props.params.id) {
            this.loadBlogPost(this.props.params.id)
        }
    }

    componentWillUnmount() {
        // Remove event handler so iframes on other parts of the marketing site won't trigger the event
        // Also prevents two events being fired from a single Iframe
        window.removeEventListener("blur", this.sendVideoEventTracking)
        this.props.clearBlogDetail()
    }

    sendVideoEventTracking = () => {
        const { tagName, src } = document.activeElement
        if (tagName === "IFRAME") {
            let eventCategory
            let eventLabel

            if (src.includes("youtube") || src.includes("vimeo")) {
                eventCategory = "Video"
                eventLabel = `Video Click - ${src}`
            } else if (src.includes("quorum.us/spreadsheet/external/")) {
                eventCategory = "Quorum Sheet"
                eventLabel = `Click in sheet - ${src}`
            }

            if (eventCategory) {
                gtag('event', window.location.pathname, {
                    event_category: eventCategory,
                    event_label: eventLabel,
                })
            }
        }
    }

    loadBlogPost(id) {
        this.props.fetchBlogDetail(id).then(() =>
            this.props.fetchBlogList(`?random=${id}`)
        )
    }


    renderSubtitle = () => {
        const { subtitle, date } = this.props
        const showDate = date && moment(moment.now()).diff(date, 'days') <= 365

        if (subtitle && !showDate) {
            return subtitle
        }
        if (!subtitle && showDate) {
            return moment(date).format("MMMM D, YYYY")
        }
        if (subtitle && showDate) {
            return `${subtitle} | ${moment(date).format("MMMM D, YYYY")}`
        }
    }

    authorInformation = () => {
        const { author, email, date } = this.props
        if (author || email) {
            return (
                <Col
                    md={8}
                    mdOffset={2}
                    sm={10}
                    smOffset={1}
                    xs={10}
                    xsOffset={1}
                    className="blog-detail-byline-author"
                >
                    {
                        author &&
                        <div>{`Written by ${author}`}</div>
                    }
                    {
                        email &&
                        <div>{email}</div>
                    }
                    {
                        date && moment(moment.now()).diff(date, 'days') <= 365 &&
                        <div>{moment(date).format("MMMM D, YYYY")}</div>
                    }
                </Col>
            )
        }
    }

    render() {
        return (
            <div className="blog-page">
                <Helmet>
                    <title>
                        {metadataTitle(this.props.title)}
                    </title>
                    <meta
                        name="description"
                        content={getPreviewFromHtmlContent(this.props.content)}
                    />
                    {/* Facebook */}
                    <meta
                        property="og:title"
                        content={metadataTitle(this.props.title)}
                    />
                    <meta
                        property="og:description"
                        content={getPreviewFromHtmlContent(this.props.content)}
                    />
                    <meta
                        property="og:image"
                        content={this.props.featured_image}
                    />
                    {/* Twitter */}
                    <meta
                        name="twitter:title"
                        content={metadataTitle(this.props.title)}
                    />
                    <meta
                        name="twitter:description"
                        content={getPreviewFromHtmlContent(this.props.content)}
                    />
                    <meta
                        name="twitter:image"
                        content={this.props.featured_image}
                    />
                    {/* This Twitter widget "scans the DOM on execution, converting blockquote.twitter-tweet elements into fully-rendered embedded Tweets." */}
                    {/* https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/overview */}
                    <script
                        async
                        src="https://platform.twitter.com/widgets.js"
                        charSet="utf-8"
                    />
                </Helmet>
                {
                    this.props.shouldUseFormHeader
                        ? <HeroImage
                            imgPath={this.props.featured_image}
                            title={this.props.title ? stripHtml(this.props.title) : ""}
                            subtitle={this.props.description || ""}
                            backgroundPositionY={"0%"}
                            formHeader={this.props.formHeader}
                            ctaButtonText={this.props.ctaButtonText}
                            gatedContentUrl={this.props.gatedContentUrl}
                            slug={this.props.slug}
                            whitePaperLink={this.props.whitePaperLink}
                            fullHeaderRequestForm
                            blogDetailPost
                        />
                        : <HeroImage
                            imgPath={this.props.featured_image}
                            title={this.props.title ? stripHtml(this.props.title) : ""}
                            subtitle={this.renderSubtitle()}
                            backgroundPositionY={"0%"}
                        />
                }
                <Row
                    className="blog-detail-content-wrapper"
                >
                    <SocialMediaSidebar
                        title={this.props.title}
                        position="top"
                    />
                    <Col
                        md={8}
                        mdOffset={2}
                        sm={10}
                        smOffset={1}
                        xs={10}
                        xsOffset={1}
                        dangerouslySetInnerHTML={{ __html: this.props.content }}
                        className="blog-detail-content"
                    />
                    {
                        this.props.hubspot_cta &&
                        <Col
                            md={8}
                            mdOffset={2}
                            sm={10}
                            smOffset={1}
                            xs={10}
                            xsOffset={1}
                            dangerouslySetInnerHTML={{ __html: this.props.hubspot_cta }}
                            className="blog-detail-hubspot-cta"
                        />
                    }
                    { this.authorInformation() }
                    <SocialMediaSidebar
                        title={this.props.title}
                        position="bottom"
                    />
                </Row>
                <Row className="email-request-form-wrapper">
                    <div
                        id="subscribe"
                    />
                    <Col
                        md={8}
                        mdOffset={2}
                        sm={10}
                        smOffset={1}
                        xsHidden
                        className="email-request-form"
                    >
                        <h3
                            className="email-signup-header"
                        >
                            Sign Up For Our Content
                        </h3>
                        <EmailRequest
                            bsSize="large"
                            label="Sign Up For Our Content"
                        />
                    </Col>
                    <Col
                        lgHidden
                        mdHidden
                        smHidden
                        xs={10}
                        xsOffset={1}
                        className="email-request-form"
                    >
                        <h3
                            className="email-signup-header"
                        >
                            Sign Up For Our Content
                        </h3>
                        <EmailRequest
                            bsSize="large"
                            label="Sign Up For Our Content"
                            shortPlaceholder
                        />
                    </Col>
                </Row>
                <Row
                    className="blog-detail-related-content-wrapper"
                >
                    <Col
                        md={10}
                        mdOffset={1}
                        sm={10}
                        smOffset={1}
                        xs={10}
                        xsOffset={1}
                        className="blog-detail-related-content"
                    >
                        <h3
                            className="related-content-header"
                        >
                            Related Content
                        </h3>
                        {
                            this.props.posts.map(post => (
                                <BlogThumbnail
                                    key={post.id}
                                    id={post.id}
                                    blogPostType={post.blog_post_type}
                                    title={post.title}
                                    imgPath={post.featured_image}
                                    shortCode={post.shortcode}
                                />
                            ))
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}

BlogDetail.defaultProps = {
    title: "",
    subtitle: "",
    content: "",
    hubspot_cta: "",
    featured_image: "",
    author: "",
    date: "",
    gatedContentUrl: "",
    slug: "",
    whitePaperLink: "",
}

BlogDetail.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.string,
    hubspot_cta: PropTypes.string,
    featured_image: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.string,
    gatedContentUrl: PropTypes.string,
    slug: PropTypes.string,
    whitePaperLink: PropTypes.string,
    fetchBlogDetail: PropTypes.func.isRequired,
    fetchBlogList: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    clearBlogDetails: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, actionCreators)(BlogDetail)
