import React from "react"

import { Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import classNames from "classnames"
import SegueLink from "middleware/SegueLink"

import VisibleSection from "quorum/static/frontend/marketing-website/components/VisibleSection"
import ReadMore from "quorum/static/frontend/marketing-website/components/ReadMore"

import "quorum/static/frontend/marketing-website/stylus/FeaturedArticle.styl"

const FeaturedArticle = ({
    leadTitle,
    title,
    url,
    imgPath,
    external,
    previewText,
    textAlignedRight,
    productLinks,
}) => {

    const externalLink = () => window.location = url

    const DummyComponent = ({ children }) => <div>{children}</div>

    // If given a url, the whole component is a SegueLink
    // Otherwise, the container is a div, and will render product buttons below
    const ArticleContainer = url
        ? SegueLink
        : DummyComponent

    return (
        <VisibleSection
            className="featured-article-section"
        >
            <Row
                className="section-holder featured-article-section-holder"
                style={{
                    backgroundImage: `url(${imgPath})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}
            >
                <ArticleContainer
                    to={url}
                    onClick={external ? externalLink : undefined}
                >
                    <div className="feature-article-backround-transparency" />
                    <Col
                        className="section"
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                        lgOffset={1}
                        mdOffset={1}
                        smOffset={1}
                        xsOffset={1}
                    >
                        <div
                            className={classNames(
                                "section-text",
                                { "align-right": textAlignedRight }
                            )}
                        >
                            {
                                leadTitle && url &&
                                <p className="featured-article-lead-header">{leadTitle}</p>
                            }

                            <h1 className="section-main-header">{title}</h1>
                            {
                                leadTitle && !url &&
                                <p className="featured-article-lead-header featured-products-lead-header">{leadTitle}</p>
                            }
                            {
                                url &&
                                <ReadMore
                                    url={url}
                                    external={external}
                                    previewText={previewText}
                                />
                            }
                            {
                                productLinks &&
                                <div
                                    className="products-wrapper"
                                >
                                    {productLinks.map(product => (
                                        <SegueLink
                                            className="product-button"
                                            to={product.link}
                                        >
                                            {product.name}
                                        </SegueLink>
                                    ))}
                                </div>
                            }
                        </div>
                    </Col>
                </ArticleContainer>
            </Row>
        </VisibleSection>
    )
}

FeaturedArticle.defaultProps = {
    textAlignedRight: false,
}

FeaturedArticle.propTypes = {
    leadTitle: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    imgPath: PropTypes.string,
    external: PropTypes.bool,
    previewText: PropTypes.string,
    showStakeholderButton: PropTypes.bool,
    textAlignedRight: PropTypes.bool,
}

export default FeaturedArticle
