import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"
import FeaturedArticle from "quorum/static/frontend/marketing-website/components/FeaturedArticle"

import { generateStaticUrl } from "imports/desktopHelperFunctions"

const DESKTOP_PRODUCT_VALUES = {
    meta: {
        title: "Quorum for Desktop",
        description: "Get the full power of Quorum, right from your computer",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/desktop/JackPocketCaseStudyBanner.jpg"),
                title: "Quorum for Desktop",
                subtitle: "Get the full power of Quorum, natively on your computer",
                backgroundPositionY: "50%",
            }
        },
        {
            component: ProductShow,
            props: {
                id: "quorum-for-windows",
                key: "quorum-for-windows",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionLeadHeader: "",
                sectionMainHeader: "Quorum for Windows",
                sectionText: "Quorum for Windows is a fully native application that installs directly on your computer. Get the full power of Quorum, right from your Desktop. Requires Windows 7 (64-bit) or greater.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/desktop/quorum-windows.png"),
                altText: "quorum-for-windows",
                secondaryCTA: {
                    link: "https://s3.amazonaws.com/quorum-desktop/windows/Quorum+Setup+1.0.3.exe",
                    label: "Download Now",
                    external: true,
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "quorum-for-mac",
                key: "quorum-for-mac",
                textAlignedLeft: false,
                sectionLeadHeader: "",
                sectionMainHeader: "Quorum for Mac",
                sectionText: "Quorum for Mac is a fully native application that installs directly on your computer. Get the full power of Quorum, right from your Dock. Requires Mac OS 10.9 or greater.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/desktop/quorum-desktop-mac.png"),
                altText: "quorum-for-mac",
                secondaryCTA: {
                    link: "https://s3.amazonaws.com/quorum-desktop/mac/Quorum-1.0.3.dmg",
                    label: "Download Now",
                    external: true,
                }
            },
        },
        {
            component: FeaturedArticle,
            props: {
                id: "quorum-mobile-app",
                key: "quorum-mobile-app-1",
                leadTitle: "Access Quorum On the Go",
                url: "/mobile-app/",
                title: "Looking for our mobile app? Click here to learn about how you can access Quorum on iPhone and Android.",
                external: false,
                imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/new_mobile_app.jpg"),
                previewText: "Learn More"
            },
        },
    ],
}

export default DESKTOP_PRODUCT_VALUES
