import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import SegueLink from "middleware/SegueLink"
import NAVIGATION_VALUES from "quorum/static/frontend/marketing-website/constants/navigationValues"
import * as Logos from "quorum/static/frontend/marketing-website/constants/logos"
import { Navbar, Nav, NavDropdown, DropdownItem, Button, Container, Form } from "react-bootstrap"

import urls from "shared/marketing-website/constants/urls"
import loginUrls from "shared/marketing-website/constants/loginUrls"

import NavigationMenuItem from "quorum/static/frontend/marketing-website/components/NavigationMenuItem"
import { segue } from "quorum/static/frontend/marketing-website/action-creators"
import { showDemoModal } from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"
import { scrollToJobListings } from "quorum/static/frontend/marketing-website/constants/functions"

import "quorum/static/frontend/marketing-website/stylus/Navigation.styl"

export const ClickableDropdown = ({ title, items, url, segue, externalLink, onSelect }) => {
    const renderIndividualMenuItems = (menuItems, parentEventKey) => {
        return menuItems.map((item) =>
            !item.externalLink ? (
                <NavigationMenuItem
                    eventKey={`${parentEventKey}.${item.url}`}
                    key={`${parentEventKey}.${item.url}`}
                    text={item.text}
                    url={item.url}
                    segue={segue}
                    onSelect={onSelect}
                />
            ) : (
                <DropdownItem
                    key={`${parentEventKey}.${item.url}`}
                    href={item.url}
                    target="_blank"
                    className="nav-menu-item"
                >
                    {item.text}
                </DropdownItem>
            ),
        )
    }

    const segueToUrl = (e) => {
        e.preventDefault()
        if (!externalLink) {
            // Trigger onSelect callback to close nav dropdown menu
            onSelect()
            return url ? segue(url) : null
        } else {
            return (window.location = url)
        }
    }

    return (
        <NavDropdown
            eventKey={title}
            key={title}
            title={title}
            id={title}
            onClick={items.length === 0 ? segueToUrl : undefined}
            href={url}
            className={items.length === 0 && "no-items"}
        >
            {renderIndividualMenuItems(items)}
        </NavDropdown>
    )
}

ClickableDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    segue: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    externalLink: PropTypes.bool,
    url: PropTypes.string,
}

const mapStateToProps = (state) => ({
    hideNavigationBarItems: selectors.selectHideNavigationBarItems(state),
})

export class Navigation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            browserWidth: window.document.body.offsetWidth,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange)
    }

    handleWindowSizeChange = () => {
        this.setState({ browserWidth: window.document.body.offsetWidth })
    }

    renderMenuDropDowns = (list) =>
        list
            .filter(
                (dropDownObj) =>
                    !(dropDownObj.excludedUrl && dropDownObj.excludedUrl === window.location.pathname) &&
                    dropDownObj.displayRanges.find(
                        (range) => this.state.browserWidth > range[0] && this.state.browserWidth <= range[1],
                    ),
            )
            .map((dropDownObj) => (
                <ClickableDropdown key={`${dropDownObj.title}-dropdown`} {...dropDownObj} {...this.props} />
            ))

    login = () => {
        if (window.isLoggedIn) {
            window.location = urls.home
        } else {
            this.props.segue(loginUrls.login)
        }
    }

    jobListingsOrRequestDemoButton = () => (
        <Button
            type="submit"
            key="demo"
            className="btn-lg request-demo-btn side-button"
            onClick={this.props.careersPage ? scrollToJobListings : () => this.props.showDemoModal("Menu")}
        >
            {this.props.careersPage ? "Join Our Team" : "Request Demo"}
        </Button>
    )

    render() {
        return (
            <Navbar
                collapseOnSelect
                fixedTop
                className="navigation-main affix-top"
                data-spy="affix"
                data-offset-top="100"
            >
                <Container>
                    <Navbar.Brand>
                        <SegueLink to={window.isLoggedIn ? urls.homepage : urls.index}>
                            <div className="logo-container" dangerouslySetInnerHTML={{ __html: Logos.whiteLogoSvg }} />
                        </SegueLink>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Container>
                {
                    <Navbar.Collapse>
                        {this.props.hideNavigationBarItems ? (
                            <Navbar.Form pullRight>{this.jobListingsOrRequestDemoButton()}</Navbar.Form>
                        ) : (
                            <div>
                                <Form pullRight>
                                    {this.jobListingsOrRequestDemoButton()}
                                    <Button
                                        type="submit"
                                        key="login"
                                        className="btn-lg login-btn side-button"
                                        onClick={this.login}
                                    >
                                        Login <i className="fa fa-chevron-circle-right" />
                                    </Button>
                                </Form>
                                <Nav pullRight>{this.renderMenuDropDowns(NAVIGATION_VALUES)}</Nav>
                            </div>
                        )}
                    </Navbar.Collapse>
                }
            </Navbar>
        )
    }
}

Navigation.propTypes = {
    careersPage: PropTypes.bool.isRequired,
    segue: PropTypes.func.isRequired,
    showDemoModal: PropTypes.func.isRequired,
    hideNavigationBarItems: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, { segue, showDemoModal })(Navigation)
