import React from "react"
import PropTypes from "prop-types"

const FormInput = (props) => {
    const getValue = (formValues) => (
        formValues
            ? formValues.get(props.fieldKey)
            : ""
    )

    const validate = value => (
        value ? props.validationFunction(value) : null
    )

    const handleChange = (e) => {
        props.updateForm(
            props.formKey,
            { [props.fieldKey]: e.target.value },
            { [props.fieldKey]: validate(e.target.value) },
        )
    }

    const getValidationState = (value) => {
        if (value && value.length) {
            return validate(value) ? 'success' : 'error'
        } else {
            return null
        }
    }

    const renderChildren = () => {
        const ChildComponent = props.presentationComponent
        return (
            <ChildComponent
                getValue={getValue}
                handleChange={handleChange}
                getValidationState={getValidationState}
                {...props}
            />
        )
    }

    return (
        <div>
            {renderChildren()}
        </div>
    )
}

FormInput.propTypes = {
    updateForm: PropTypes.func.isRequired,
    validationFunction: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    fontAwesomeIcon: PropTypes.string,
    placeHolderText: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    fieldKey: PropTypes.string.isRequired,
    presentationComponent: PropTypes.func.isRequired,
}

export default FormInput
