import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"
import FeaturedArticle from "quorum/static/frontend/marketing-website/components/FeaturedArticle"

import { generateStaticUrl } from "imports/desktopHelperFunctions"

const GRASSROOTS_PRODUCT_VALUES = {
    meta: {
        title: "Grassroots",
        description: "Mobilize your Advocates with digital action centers and district mapping, all integrated into Quorum's public affairs software.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/grassroots_banner.jpg"),
                title: "Grassroots",
                subtitle: "Mobile Optimized Action Center, Advocate Directory, and Email Tool.",
                backgroundPositionY: "60%",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "legislative-tracking",
                key: "legislative-tracking",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "21st Century Action Center",
                sectionLeadHeader: "Engage Your Advocates Online and Offline",
                sectionText: "Use our innovative combined campaigns to engage advocates in multiple actions with a single click. Launch campaigns like write a letter, visit an office, call their official, sign a petition, tweet at someone, share a link, comment on a regulation, write to the White House, or tell their story. Once they’ve taken action, seamlessly redirect advocates to an external page like a donation center or blog.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/engage_advocates_2.png"),
                altText: "engage-advocates",
                secondaryCTA: {
                    link: "/resources/grassroots-combined-campaigns/145/",
                    label: "Maximize Grassroots Impact with Combined Campaigns"
                }
            },
        },
        {
            component: FeaturedArticle,
            props: {
                id: "grassroots-advocacy-campaigns",
                key: "grassroots-advocacy-campaigns",
                leadTitle: "Featured Whitepaper",
                url: "/resources/zero-barriers-advocacy/143/",
                title: "A Zero Barriers Approach to Grassroots Advocacy",
                external: false,
                imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/QuorumGrassroots_Mobile1.jpg")
            },
        },
        {
            component: ProductShow,
            props: {
                id: "patch-through-calls",
                key: "patch-through-calls",
                textAlignedLeft: false,
                sectionMainHeader: "Patch Through Calls",
                sectionLeadHeader: "Make Calling Legislators Easy",
                sectionText: "Quorum’s advanced patch through calling system makes it easy for your advocates to call their legislator, and provides you with meaningful data on the number of calls your advocates are making.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/make_calling_legislators_easy_2.png"),
                altText: "make-calling-legislators-easy",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "targeting-and-filtering",
                key: "targeting-and-filtering",
                textAlignedLeft: true,
                theme: "section-purple",
                sectionMainHeader: "Targeting and Filtering",
                sectionLeadHeader: "Be More Strategic With Your Outreach",
                sectionText: "Powerful targeting tools enable you to identify your strongest advocates and engage the right legislators by filtering through committee membership, voting records, legislative districts, past participation in campaigns, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/strategic_outreach_2.png"),
                altText: "strategic-outreach",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "issue-profiles",
                key: "issue-profiles",
                textAlignedLeft: false,
                sectionMainHeader: "Comprehensive Issue Profiles",
                sectionLeadHeader: "Never Stop Educating",
                sectionText: "Educate your supporters on the issues your organization cares about and why their support matters. Provide background on the issues their legislator works on the most, the committees they serve on, and their legislative effectiveness and bipartisanship.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/never_stop_educating_2.png"),
                altText: "never-stop-educating",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "customized-email-tools",
                key: "customized-email-tools",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Integrated Email Tool",
                sectionLeadHeader: "Make Your Emails Count",
                sectionText: "No need to know HTML. Quorum Outbox makes it easy to design and send emails to key contacts, stakeholders, and advocates and measure your impact by tracking open rates, clicks, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/make_emails_count_2.png"),
                altText: "make-emails-count",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "cross-platform-integration",
                key: "cross-platform-integration",
                textAlignedLeft: false,
                sectionMainHeader: "Cross-platform Integration",
                sectionLeadHeader: "Close The Loop With Government Affairs",
                sectionText: "Grassroots campaigns seamlessly integrate with Quorum Federal and State to maximize coordination with your organization's legislative strategy. Government affairs teams can view advocate engagement in their tool, making it easier to share valuable information.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/close_the_loop_2.png"),
                altText: "close-the-loop",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "zero-barriers",
                key: "zero-barriers",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Magic Links",
                sectionLeadHeader: "Zero Barriers to Action",
                sectionText: "Seamlessly upload advocate contact information and embed them into magic links. The moment an advocate clicks your link, they’ll be automatically signed into your Action Center and ready to take action - without ever having to fill out a form.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/magic-links.png"),
                altText: "grassroots-magic-links",
                secondaryCTA: {
                    link: "/resources/zero-barriers-advocacy/143/",
                    label: "Learn How Streamlining Taking Action Improves Results"
                }
            },
        },
    ]
}

export default GRASSROOTS_PRODUCT_VALUES
