// attaches the locationBeforeTransitions pathname to
// every actions
import { LOCATION_CHANGE } from "app/static/frontend/global-action-types"

const pathnameMiddleware = store => next => action => {
    // if this is a location change
    if (action && action.type === LOCATION_CHANGE) {
        // inspect the routing state
        const { routing } = store.getState()
        // if not the initial load
        if (routing.get("locationBeforeTransitions")) {
            // bind pathname to action so we know where we're coming from
            action.previousPathname = routing.get("locationBeforeTransitions").pathname
            action.previousQuery = routing.get("locationBeforeTransitions").search || ""
        }
    }

    next(action)
}
export default pathnameMiddleware
