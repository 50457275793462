/*
    - Replicates exactly the behavior of subscriptions.coffee in JavaScript.
    - This is bad code objectively, and we really need to rewrite the way that we
    publish userdata and permissions.
    - This file is essentially a translation from CoffeeScript to JavaScript
    to allow the mobile application to be consistent with the rest of our site
    (React Native can't deal with CoffeeScript files), but at some point we
    need to seriously rethink this system.
*/

import CookieJar from "shared/imports/CookieJar"

window.PUBLICATIONS && window.PUBLICATIONS.forEach && window.PUBLICATIONS.forEach((publication) => {
    if (publication.name === "cookies") {
        window.Userdata = new CookieJar(publication.data)
        window.userdata = window.Userdata
        window.Userdata.readToken()
        window.Userdata.setCurrentRegion()
    }
})
