// userdata subscriptions
import "shared/imports/subscriptions.js"

// bootstrap
import "bootstrap"

// React
import React from "react"
import ReactDOM from "react-dom"

// React Router
import { BrowserRouter, Routes, Route } from "react-router-dom"

// Redux
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose, combineReducers } from "redux"
import thunk from "redux-thunk"

// Individual Reducers
import marketingReducer from "shared/marketing-website/marketing-reducer"
import routingReducer from "routing-reducer"

// Redux Middlewares
import previousPathnameMiddleware from "middleware/previousPathnameMiddleware"
import segueMiddleware from "middleware/segueMiddleware"

// Urls
import urls from "shared/marketing-website/constants/urls"
import loginUrls from "shared/marketing-website/constants/loginUrls"

// Components for Routes
import MarketingFrame from "quorum/static/frontend/marketing-website/containers/MarketingFrame"
import HomePage from "quorum/static/frontend/marketing-website/containers/HomePage"
import ResourcesPage from "quorum/static/frontend/marketing-website/containers/ResourcesPage"
import BlogDetail from "quorum/static/frontend/marketing-website/containers/BlogDetail"
import BlogDetailDownload from "quorum/static/frontend/marketing-website/containers/BlogDetailDownload"
import CareersPage from "quorum/static/frontend/marketing-website/containers/CareersPage"
import AboutUsPage from "quorum/static/frontend/marketing-website/containers/AboutUsPage"
import PressPage from "quorum/static/frontend/marketing-website/containers/PressPage"
import LoginPage from "quorum/static/frontend/marketing-website/containers/LoginPage"
import MobileRegistrationLanding from "frontend/registration/containers/MobileRegistrationLanding"
import withRouter from "app/static/frontend/routing/withRouter"
import * as ProductPages from "quorum/static/frontend/marketing-website/containers/ProductPages"

// Config
const composeWithDevTool = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = [thunk, previousPathnameMiddleware, segueMiddleware]

// Create Redux store
const reducer = combineReducers({
    marketing: marketingReducer,
    routing: routingReducer,
})

const store = createStore(reducer, composeWithDevTool(applyMiddleware(...middlewares)))

// Not great practice, but very helpful for debugging
window.store = store

const MarketingRouter = (
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route index component={HomePage} />
                <Route path={urls.mobileRegistrationLanding} Component={MobileRegistrationLanding} />
                <Route path="/" Component={MarketingFrame}>
                    {/* Home Page */}
                    <Route index Component={HomePage} />
                    <Route path={urls.homepage} Component={HomePage} />
                    {/*
                    Deprecated EU homepage
                    <Route path={urls.euHomepage} Component={EuHomePage} />
                    */}

                    {/* Use Case Pages */}
                    <Route path={urls.stakeholderEngagement} Component={ProductPages.StakeholderEngagementPage} />
                    <Route path={urls.legislativeTracking} Component={ProductPages.LegislativeTrackingPage} />
                    <Route path={urls.grassrootsAdvocacy} Component={ProductPages.GrassrootsAdvocacyPage} />

                    {/* Paid search page with custom hero image */}
                    <Route
                        path={urls.legislativeTrackingSoftware}
                        Component={ProductPages.LegislativeTrackingSoftwarePage}
                    />

                    {/* Products */}
                    <Route path={urls.products} Component={ProductPages.ProductsPage} />
                    <Route path={urls.federalProduct} Component={ProductPages.FederalProduct} />
                    <Route path={urls.stateProduct} Component={ProductPages.StateProduct} />
                    <Route path={urls.grassrootsProduct} Component={ProductPages.GrassrootsProduct} />
                    <Route path={urls.localProduct} Component={ProductPages.LocalProduct} />
                    <Route path={urls.stakeholderProduct} Component={ProductPages.StakeHolderProduct} />
                    <Route path={urls.euProduct} Component={ProductPages.EuProduct} />
                    <Route path={urls.internationalProduct} Component={ProductPages.InternationalProduct} />

                    <Route path={urls.mobileProduct} Component={ProductPages.MobileProduct} />
                    <Route path={urls.mobileEuProduct} Component={ProductPages.MobileEuProduct} />
                    <Route path={urls.desktopProduct} Component={ProductPages.DesktopProduct} />
                    <Route path={urls.regulationsProduct} Component={ProductPages.RegulationsProduct} />

                    {/* Resources */}
                    <Route path={urls.resources} Component={ResourcesPage} />
                    <Route path={urls.resourcesWithParams} Component={ResourcesPage} />
                    <Route path={urls.dataDrivenInsightsDetail} Component={BlogDetail} />
                    <Route path={urls.productivityDetail} Component={BlogDetail} />
                    <Route path={urls.behindTheDeskDetail} Component={BlogDetail} />
                    <Route path={urls.bestPracticesDetail} Component={BlogDetail} />
                    <Route path={urls.publicAffairsDictionaryDetail} Component={BlogDetail} />
                    <Route path={urls.caseStudiesDetail} Component={BlogDetail} />
                    <Route path={urls.blogDetailDownload} Component={BlogDetailDownload} />

                    <Route path={urls.careers} Component={CareersPage} />

                    {/* About */}
                    <Route path={urls.aboutUs} Component={AboutUsPage} />
                    <Route path={urls.press} Component={PressPage} />
                    <Route path={urls.security} Component={ProductPages.SecurityPage} />
                </Route>
                {/* Login Pages */}

                <Route path={loginUrls.login} Component={withRouter(LoginPage)} />
                <Route path={loginUrls.loginWithParams} Component={withRouter(LoginPage)} />
                <Route path={loginUrls.logout} Component={withRouter(LoginPage)} />
                <Route path={loginUrls.recover} Component={withRouter(LoginPage)} />
                <Route path={loginUrls.reset} Component={withRouter(LoginPage)} />
            </Routes>
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(MarketingRouter, document.getElementById("marketing-app"))
