import React from "react"
import { generateStaticUrl } from "imports/desktopHelperFunctions"
import styled from "styled-components"
import { Button } from "react-bootstrap"
const StyledBackgroundImage = styled.div`
    background-size: cover;
    background-image: url(${generateStaticUrl(
        "apps/marketing-website/images/hero_images/resized/stakeholder_banner.jpg",
    )});
    height: 100vh;
`

const Container = styled.div`
    padding-top: 54px;
    padding-right: 40px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const StyledAppStore = styled.img`
    height: 122px;
`

const StyledLogo = styled.img`
    margin-bottom: 13px;
    height: 198px;
`

const StyledBlockTitle = styled.div`
    font-weight: 200;
    font-size: 45pt;
    padding-bottom: 11px;
`

const StyledBlockDescription = styled.div`
    font-size: 30pt;
    padding-bottom: 40px;
`

const StyledButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
`

const StyledBlock = styled.div`
    border-radius: 12px;
    margin-top: 35px;
    margin-bottom: 50px;
    padding: 49px;
    background-color: #fbfbfc;
    width: 100%;
`

const StyledTopRowContainer = styled.div`
    display: flex;
`

const StyledStepNumberColumn = styled.div`
    flex-basis: 17%;
`
const StyledStepNumber = styled.div`
    border-radius: 50%;
    font-weight: 300;
    height: 105px;
    width: 105px;
    background-color: #5b3179;
    color: #fbfbfc;
    text-align: center;
    font-size: 55pt;
`

const StyledTextContainer = styled.div`
    flex-basis: 83%;
    margin-top: 12px;
`

const StyledButtonText = styled.span`
    font-size: 30pt;
    margin-right: 17px;
`

const AppStoreButtons = () => (
    <StyledButtonsWrapper>
        <a href="https://apps.apple.com/us/app/quorum-mobile/id1084463500?mt=8">
            <StyledAppStore alt="Apple Store" src={generateStaticUrl("common/images/app-store.png")} />
        </a>
        <a href="market://details?id=com.quorummobile">
            <StyledAppStore alt="Play Store" src={generateStaticUrl("common/images/google-play.png")} />
        </a>
    </StyledButtonsWrapper>
)

const LaunchButton = ({ activationKey }) => (
    <StyledButtonsWrapper>
        <Button
            block
            onClick={() => window.location.assign(`/accounts/activate/${activationKey}/`)}
            variant="danger"
            bsSize="lg"
            style={{ height: "113px", "background-color": "#3b3561" }}
        >
            <StyledButtonText>Launch</StyledButtonText>
        </Button>
    </StyledButtonsWrapper>
)

const Block = ({ title, description, stepNumber, Buttons, activationKey }) => (
    <StyledBlock>
        <StyledTopRowContainer>
            <StyledStepNumberColumn>
                <StyledStepNumber>{stepNumber}</StyledStepNumber>
            </StyledStepNumberColumn>
            <StyledTextContainer>
                <StyledBlockTitle>{title}</StyledBlockTitle>
                <StyledBlockDescription>{description}</StyledBlockDescription>
            </StyledTextContainer>
        </StyledTopRowContainer>
        {Buttons && <Buttons activationKey={activationKey} />}
    </StyledBlock>
)

// This component renders a simple landing page for mobile users that try to activate their accounts
// from a mobile web browser.
const MobileRegistrationLanding = (props) => (
    <StyledBackgroundImage className="mobile-registration-landing">
        <Container>
            <StyledLogo
                alt="Welcome to Quorum"
                className="banner"
                src={generateStaticUrl("common/images/welcome_to_quorum_logo.png")}
            />
            <Block
                description={`Make sure to have the app installed. If you don't already, visit the app store to download.
                Return here to launch with your special link.`}
                stepNumber={1}
                title="Download"
                Buttons={AppStoreButtons}
            />
            <Block
                activationKey={props.params.activation_key}
                description={`Once the app is installed, click below to launch the app with your special
                link. You'll then be prompted to create an account.`}
                stepNumber={2}
                title="Launch"
                Buttons={LaunchButton}
            />
        </Container>
    </StyledBackgroundImage>
)

export default MobileRegistrationLanding
