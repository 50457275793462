import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Row, Col } from "react-bootstrap"

import * as actionCreators from "shared/marketing-website/action-creators"

import BaseHelmet from "quorum/static/frontend/marketing-website/containers/BaseHelmet"
import Navigation from "quorum/static/frontend/marketing-website/components/Navigation"
import Footer from "quorum/static/frontend/marketing-website/components/Footer"
import RequestDemo from "quorum/static/frontend/marketing-website/components/RequestDemo"
import urls from "shared/marketing-website/constants/urls"

import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues"

import "css/bootstrap.css"
import "css/font-awesome.css"
import "css/sweetalert.css"

import "quorum/static/frontend/marketing-website/stylus/MarketingFrame.styl"
import "quorum/static/frontend/marketing-website/stylus/Section.styl"
import "quorum/static/frontend/marketing-website/stylus/Animations.styl"
import "quorum/static/frontend/marketing-website/stylus/Button.styl"

// No selectors needed for this container, but still need to pass something for mapStateToProps
const mapStateToProps = (state, props) => ({})

export class MarketingFrame extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)

        if (this.props.location.hash === MARKETING_SITE_VALUES.requestDemoAnchor) {
            // If url includes '#request-demo', instead of scrolling to the request demo button, we open the form immediately
            this.props.showDemoModal("Direct")
        }
    }

    componentDidUpdate() {
        window.scrollTo(0, 0)
    }

    // If on the careers page, pass prop to Navigation so a `Join Our Tream' button is rendered instead of 'Request Demo'
    careersPageCheck = () => this.props.location.pathname === urls.careers

    // Do not render Request Demo form for these urls.
    // For index and homepage, we render Request Demo form in Homepage.jsx, not the marketing frmae
    // For the careers page, do not want to confuse applicants with a Request Demo form.
    blacklistedCheck = () => {
        const blacklistedUrls = [
            urls.index,
            urls.homepage,
            urls.careers,
            urls.security,
        ]

        return blacklistedUrls.includes(window.location.pathname)
    }

    render() {
        return (

            <Row className="marketing-frame">
                <BaseHelmet />
                <Navigation
                    careersPage={this.careersPageCheck()}
                />
                <Col
                    className="marketing-frame-children"
                    md={12}
                >
                    {this.props.children}
                    {
                        !this.blacklistedCheck() &&
                        <RequestDemo />
                    }
                    <Footer
                        location={this.props.location}
                    />
                </Col>
            </Row>
        )
    }
}

MarketingFrame.propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
    showDemoModal: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, actionCreators)(MarketingFrame)
