import { generateStaticUrl } from "imports/desktopHelperFunctions"

const ABOUT_US_VALUES = {
    title: "About Our Company",
    subtitle: "",
    imgSrc: generateStaticUrl("apps/marketing-website/images/careers/banner/conference_room.JPG"),
    metaTitle: "About Us",
    metaDescription: "Public affairs professionals should have modern software that’s built for them. That’s why we built Quorum.",
    // ProductShow sections
    sections: [
        {
            id: "modern-software",
            sectionMainHeader: "Modern Software Built For You",
            sectionText: "If you work in Marketing, Sales, or Human Resources, you probably use software designed to make your work easier and more impactful. If you work in Public Affairs, you’ve been left to use software built with someone else in mind. Not anymore. We built Quorum as an integrated public affairs software platform from the ground up. With Quorum, you get one platform, one company, one place for stakeholder engagement, legislative tracking, and grassroots advocacy.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/LegTrackingQuorum.png"),
            altText: "legislative-tracking",
            textAlignedLeft: true,
            theme: "section-grey",
            requestDemoForm: true,
        },
        {
            id: "how-we-got-started",
            sectionMainHeader: "Our Bootstrapped Beginning",
            sectionText: "Our co-founders Alex Wirth and Jonathan Marks started Quorum out of their dorm room. Alex wanted to address the problems he encountered while running an advocacy campaign on Capitol Hill, and Jonathan used his experience in computational biochemistry to find a solution. What started as an algorithm to figure out who members of Congress worked with most frequently has evolved into a fully integrated software platform across seven products that offers solutions in stakeholder engagement, legislative tracking, and grassroots advocacy.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/quorum-pitch.jpeg"),
            altText: "how-we-got-started",
            textAlignedLeft: false,
            removeSecondayCTA: true,
        },
        {
            id: "clients-succeed",
            sectionMainHeader: "We Only Succeed If Our Clients Succeed",
            sectionText: "We have never raised a single dollar of venture capital funding, meaning we only succeed if our clients succeed. Our products are a direct reflection of the feedback we get from clients and our commitment to build an integrated platform that is indispensable to all users. ",
            featuredPosts: true,
            textAlignedLeft: true,
            theme: "section-blue",
            secondaryCTA: {
                label: "Read Our Clients' Success Stories",
                link: "/resources/case-studies/",
            },
        },
        {
            id: "team-make-it-happen",
            sectionMainHeader: "The Team That Makes It Happen",
            sectionText: "Quorum is headquartered in downtown Washington D.C., with an international office in Brussels. Our team is comprised of former Hill staffers, campaign vets, engineers, and everyone in between—including marathon runners, blacksmiths, chefs, and more. ",
            images: [
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/bus_team.JPG"),
                    altText: "business-team",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/dev_team.JPG"),
                    altText: "business-team",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/whiteboard.JPG"),
                    altText: "whiteboarding",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/collaborative_environment.jpeg"),
                    altText: "collaborative-environment",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/louie.JPG"),
                    altText: "louie-the-dog",
                },
            ],
            textAlignedLeft: false,
            theme: "section-grey",
            secondaryCTA: {
                label: "Join Our Team",
                link: "/about/careers/",
            },
        },
        {
            id: "commitment-to-trust-and-progress",
            sectionMainHeader: "Our Commitment to Trust and Progress",
            sectionText: "At Quorum, diversity and inclusion means trust and progress. Trust because people who trust one another grow with one another and progress because the work is never done.",
            images: [
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/mlk_day2.png"),
                    altText: "martin-luther-king-day",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/lunar_new_year2.png"),
                    altText: "lunar-new-year",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/building_inclusive_spaces2.JPG"),
                    altText: "building-inclusive-spaces-in-tech",
                },
            ],
            textAlignedLeft: true,
            multipleCtaButtons: [
                {
                    secondaryCTA: {
                        label: "Quorum's Inclusion and Diversity Charter",
                        link: generateStaticUrl("apps/marketing-website/Quorum.D.I.Charter.pdf"),
                        external: true,
                    },
                },
                {
                    secondaryCTA: {
                        label: "Quorum's Inclusion and Diversity Report",
                        link: generateStaticUrl("Quorum.2018.Diversity.and.Inclusion.Report.pdf"),
                        external: true,
                    },
                },
            ],
        },
    ],
    biographies: [
        {
            name: "Alex Wirth",
            text: "Alex Wirth is the co-founder and CEO of Quorum. Alex was named by Washingtonian as one of D.C.’s 100 Top Tech Leaders, is a World Economic Forum Global Shaper and sits on the Government Relations Executives Council, the U.S. National Commission for UNESCO, the Board of Directors of the Public Affairs Council, and the Board of Directors of the Forum for Youth Investment.",
            text2: "Alex has spoken at the United Nations, Clinton Global Initiative, World Forum for Democracy, SXSW, and TedxFoggyBottom. Originally from Santa Fe, New Mexico, Alex graduated from Harvard with a degree in Government and Economics and worked in the White House and U.S Senate before starting Quorum. Follow him on Twitter <a href=\"https://twitter.com/amaliowirth\" target=\"_blank\">@amaliowirth</a>.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/awirth.jpeg"),
            textAlignedLeft: true,
        },
        {
            name: "Jonathan Marks",
            text: "Jonathan Marks is the co-founder and CTO of Quorum and is primarily responsible for product design and development. His work with Quorum has been recognized as a Model of Excellence by the InfoCommerce group and he was named by Washingtonian as one of D.C.’s 100 Top Tech Leaders.",
            text2: "Jonathan has spoken about politics, entrepreneurship, and data at the Amazon Web Services re:Invent Conference, the Esri User Conference, and numerous universities. Originally from Brookfield, Wisconsin, Jonathan graduated from Harvard with a degree in Chemical and Physical Biology.",
            imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/about_us/jmarks.jpeg"),
            textAlignedLeft: false,
        },

    ],
}

export default ABOUT_US_VALUES
