import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"
import urls from "shared/marketing-website/constants/urls"

const MARKETING_SITE_VALUES = {
    currentYear: new Date().getFullYear(),
    termsOfUse: generateStaticUrl("apps/marketing-website/standard_terms_of_use.pdf"),
    privacyPolicy: "https:\/\/www.quorum.us\/privacy-policy\/",
    pressKit: generateStaticUrl("apps/marketing-website/QuorumPressKit19.pdf"),
    appStoreLink: "https://itunes.apple.com/us/app/quorum-mobile/id1084463500",
    googlePlayStoreLink: "https://play.google.com/store/apps/details?id=com.quorummobile&hl=en",
    requestDemoAnchor: "#request-demo",
    newsletterText: "Sign up to receive best practices and product announcements.",
    // THe urls in which we want to hide the navigation bar items (except Request Demo form)
    navigationBarBlacklistedUrls: [
        urls.legislativeTrackingSoftware,
    ],
    oldRequestFormUrls: [
        urls.euProduct,
    ],
}

export default MARKETING_SITE_VALUES
