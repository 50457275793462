import { createCookieByKey, readCookieByKey } from "shared/imports/sharedHelperFunctions"

export default class CookieJar {
    constructor(cookies) {
        // add all of the cookies that we find to this object
        Object.assign(this, cookies)
    }

    getCurrentRegionText() {
        if (this.current_preset_name) {
            return this.current_preset_name
        } else {
            // getCurrentRegionText can get called before DjangIo is properly initialized
            if (global.DjangIO) {
                const region = DjangIO.app.models.SupportedRegion.items().find(
                    (item) => item.abbrev === this.current_regions[0],
                )

                return region ? region.region_name : ""
            }

            return this.current_regions[0]
        }
    }

    isOnlyAllMode() {
        // true iff the current region is all regions
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.all_regions.abbrev
    }

    isFederalMode() {
        // true iff the current region is federal or all regions
        return Boolean(
            this.current_regions.toString() === DjangIO.app.models.SupportedRegion.all_regions.abbrev ||
                this.current_regions
                    .toString()
                    .split(",")
                    .find((item) => item === DjangIO.app.models.SupportedRegion.federal.abbrev),
        )
    }

    isOnlyFederalMode() {
        // true iff the current region is federal
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.federal.abbrev
    }

    isAllStatesMode() {
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.states.abbrev
    }

    isStatesMode() {
        // true if us isn't in the current regions and there exists a 2-char region
        return !!this.current_regions.find(
            (region) =>
                region === DjangIO.app.models.SupportedRegion.all_regions.abbrev ||
                region === DjangIO.app.models.SupportedRegion.states.abbrev ||
                region === DjangIO.app.models.SupportedRegion.states_and_local.abbrev ||
                (region.length === 2 &&
                    region !== DjangIO.app.models.SupportedRegion.federal.abbrev &&
                    region !== DjangIO.app.models.SupportedRegion.eu.abbrev),
        )
    }

    isOnlyStatesMode() {
        // true iff the current region is neither "all" nor "us" and there's no "local" anywhere
        return (
            [
                DjangIO.app.models.SupportedRegion.all_regions.abbrev,
                DjangIO.app.models.SupportedRegion.federal.abbrev,
            ].indexOf(this.current_regions.toString()) === -1 &&
            this.current_regions.toString().indexOf(DjangIO.app.models.SupportedRegion.local.abbrev) < 0 &&
            this.current_regions.toString().indexOf(DjangIO.app.models.SupportedRegion.eu.abbrev) < 0
        )
    }

    isAllLocalMode() {
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.local.abbrev
    }

    isLocalMode() {
        // true iff there's a "local" somewhere in the current regions
        return (
            this.current_regions.toString() === DjangIO.app.models.SupportedRegion.all_regions.abbrev ||
            this.current_regions.toString().indexOf(DjangIO.app.models.SupportedRegion.local.abbrev) > -1
        )
    }

    isOnlyLocalMode() {
        // true iff there's local in each of the current regions
        for (let region of this.current_regions) {
            if (region.indexOf(DjangIO.app.models.SupportedRegion.local.abbrev) < 0) {
                return false
            }
        }

        return true
    }

    isAllStateAndLocalMode() {
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.states_and_local.abbrev
    }

    isSingleRegionMode() {
        // true iff length of current regions is 1 and not equal to ["states"], ["local"], or ["all"]
        return (
            [
                DjangIO.app.models.SupportedRegion.all_regions.abbrev,
                DjangIO.app.models.SupportedRegion.states.abbrev,
                DjangIO.app.models.SupportedRegion.local.abbrev,
                DjangIO.app.models.SupportedRegion.states_and_local.abbrev,
                DjangIO.app.models.SupportedRegion.user_global.abbrev,
            ].indexOf(this.current_regions.toString()) === -1 && this.current_regions.length === 1
        )
    }

    isSingleStateMode() {
        // true iff current region is a single state
        return (
            ![
                DjangIO.app.models.SupportedRegion.all_regions.abbrev,
                DjangIO.app.models.SupportedRegion.states.abbrev,
                DjangIO.app.models.SupportedRegion.federal.abbrev,
                DjangIO.app.models.SupportedRegion.local.abbrev,
                DjangIO.app.models.SupportedRegion.eu.abbrev,
                DjangIO.app.models.SupportedRegion.states_and_local.abbrev,
                DjangIO.app.models.SupportedRegion.user_global.abbrev,
            ].includes(this.current_regions.toString()) &&
            this.current_regions.length === 1 &&
            this.current_regions.toString().indexOf(DjangIO.app.models.SupportedRegion.local.abbrev) < 0
        )
    }

    isSingleLocalMode() {
        return this.current_regions.length === 1 && this.current_regions.toString().indexOf("_local") > -1
    }

    isSubsetMode() {
        // true iff length of current regions is > 1
        return this.current_regions.length > 1
    }

    isOnlyEUMode() {
        // TODO add in all to this in the future, but for now
        // we only want to access this if we are in EU only mode
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.eu.abbrev
    }

    isGlobalMode() {
        // global means every possible region is selected
        // return this.current_regions.length = Userdata.allowed_regions_values.length
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.user_global.abbrev
    }

    isInternationalMode() {
        // global means every possible region is selected
        // return this.current_regions.length = Userdata.allowed_regions_values.length
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.international.abbrev
    }

    isOnlyIntlegMode() {
        const internationalRegions = DjangIO.app.models.SupportedRegion.international.child_regions

        return this.current_regions_values.every((value) => internationalRegions.includes(value))
    }

    hasInternationalMode() {
        const internationalRegions = DjangIO.app.models.SupportedRegion.international.child_regions

        return this.current_regions_values.find((value) => internationalRegions.includes(value))
    }

    isEUMemberStates() {
        return this.current_regions.toString() === DjangIO.app.models.SupportedRegion.eu_member_states.abbrev
    }

    // inspect the csrftoken in the document and add it to the CookieJar
    readToken() {
        for (let cookie of document.cookie.split("; ")) {
            if (cookie.includes("csrftoken")) {
                this.csrftoken = cookie.split("=")[1]
            }
        }
    }

    setMobileCurrentRegion(new_region) {
        if (new_region.includes(",")) {
            const new_regions = new_region.split(",")

            // If the user has presets and the new regions match the regions in one
            // of the existing presets.
            const new_preset =
                Object.keys(this.preset_regions).length &&
                Object.entries(this.preset_regions)
                    .filter(
                        ([, value]) =>
                            value.regions.length === new_regions.length &&
                            value.regions.every((region) => new_regions.includes(region)),
                    )
                    .map(([key]) => key)
                    .find((preset) => preset)

            this.current_regions = new_regions
            this.current_preset_name = new_preset
        } else {
            this.current_regions = [new_region]
            this.current_preset_name = ""
        }
    }

    setCurrentRegion() {
        // If there is no value for the current region cookie
        if (!readCookieByKey("current_regions")) {
            createCookieByKey("current_regions", this.current_regions, 30)
        }
    }
}
