import { fromJS } from "immutable"
import { createSelector, createStructuredSelector } from "reselect"

import { makeImmutableSliceSelector } from "shared/imports/sharedHelperFunctions"
import { LoginType, loginTypeLookup } from "shared/marketing-website/constants/loginType"
import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues"

export const selectMarketingWebsiteState = (state) => state.marketing

export const selectRoutingState = (state) => state.routing

export const selectLocationBeforeTransitions = createSelector(selectRoutingState, (routing) =>
    routing ? routing.get("locationBeforeTransitions", {}) : {},
)

export const selectLocationPathname = createSelector(
    selectLocationBeforeTransitions,
    (location) => location.pathname || "/",
)

export const selectAvailablePositions = createSelector(selectMarketingWebsiteState, (state) => state.get("careers"))

export const selectBlog = createSelector(selectMarketingWebsiteState, (state) => state.get("blog"))

export const makeBlogSliceSelector = (key) => createSelector(selectBlog, (blog) => blog.get(key))

export const selectIndividualPost = createSelector(selectBlog, (blog) => blog.get("individualPost"))

export const makeBlogIndividualSelector = (key) =>
    createSelector(selectIndividualPost, (individualPost) => (individualPost ? individualPost[key] : undefined))

export const selectPaidSearchForm = createSelector(
    selectMarketingWebsiteState,
    (_, props) => props.blogDetailPost,
    (state, blogDetailPost) => state.get(blogDetailPost ? "blogDetailForm" : "requestDemoForm"),
)

export const selectPaidSearchFormValues = createSelector(selectPaidSearchForm, (form) => form.get("formValues"))

export const selectPaidSearchFormValidation = createSelector(selectPaidSearchForm, (form) => form.get("validationDict"))

export const selectRequestDemoForm = createSelector(selectMarketingWebsiteState, (state) =>
    state.get("requestDemoForm"),
)

export const selectRequestBlogForm = createSelector(selectMarketingWebsiteState, (state) => state.get("requestBlog"))

export const selectRequestDemoFormValues = createSelector(selectRequestDemoForm, (state) => state.get("formValues"))

export const requestDemoModalShown = createSelector(selectRequestDemoForm, (state) => state.get("modalShown"))

export const requestDemoEventLabel = createSelector(selectRequestDemoForm, (state) => state.get("eventLabel"))

export const selectRequestDemoEmail = createSelector(selectRequestDemoFormValues, (state) => state.get("email"))

export const selectRequestDemoValidation = createSelector(selectRequestDemoForm, (state) => state.get("validationDict"))

export const selectRequestBlogFormValues = createSelector(selectRequestBlogForm, (state) => state.get("formValues"))

export const selectRequestBlogValidation = createSelector(selectRequestBlogForm, (state) => state.get("validationDict"))

export const selectRequestBlogEmail = createSelector(selectRequestBlogFormValues, (state) => state.get("email"))

export const selectRequestBlogChoices = createSelector(selectRequestBlogFormValues, (state) => state.get("blogs"))

export const selectBlogDetailDownloadForm = createSelector(selectMarketingWebsiteState, (state) =>
    state.get("resourceForm"),
)

export const selectBlogDetailDownloadFormValues = createSelector(selectBlogDetailDownloadForm, (state) =>
    state.get("formValues"),
)

export const selectWhitePaperValidation = createSelector(selectBlogDetailDownloadForm, (state) =>
    state.get("validationDict"),
)

export const selectResourceFormSubmitted = createSelector(selectBlogDetailDownloadForm, (state) =>
    state.get("submitted"),
)
export const selectResourceFormMounted = createSelector(selectBlogDetailDownloadForm, (state) => state.get("mounted"))

export const selectResourceSearchForm = createSelector(selectMarketingWebsiteState, (state) =>
    state.get("resourceSearch"),
)

export const selectResourceSearchFormValues = createSelector(selectResourceSearchForm, (state) =>
    state.get("formValues"),
)

export const selectBlogPostType = createSelector(selectResourceSearchFormValues, (state) => state.get("blog_post_type"))

export const selectAdvancedSearchTerm = createSelector(selectResourceSearchFormValues, (state) =>
    state.get("advanced_search"),
)

// Hide navigation bar menu items (except request demo) when gated content form is mounted, or
// There are 3 different conditions in which we want to hide the navigation bar items
// 1. For any gated content pages (check if the 'resource form' is mounted)
// 2. For any specific hardcoded urls in 'navigationBarBlacklistedUrls'
// 3. For any non-gated blog posts with 'should_use_header=true'
export const selectHideNavigationBarItems = createSelector(
    selectResourceFormMounted,
    selectLocationPathname,
    makeBlogIndividualSelector("should_use_form_header"),
    (resourceFormMounted, pathname, formHeader) =>
        Boolean(
            resourceFormMounted || MARKETING_SITE_VALUES.navigationBarBlacklistedUrls.includes(pathname) || formHeader,
        ),
)

export const selectLoginPage = createSelector(selectMarketingWebsiteState, (state) => state.get("loginPage"))

export const selectLoginPageFormValues = createSelector(selectLoginPage, (state) => state.get("formValues"))

export const makeLoginPageFormValueSelector = makeImmutableSliceSelector(selectLoginPageFormValues)
export const makeSelectLoginPageSlice = makeImmutableSliceSelector(selectLoginPage)

export const selectLoginPageCurrentStep = makeSelectLoginPageSlice("currentStep")
export const selectLoginPageLoading = makeSelectLoginPageSlice("loading")
export const selectLoginPageError = makeSelectLoginPageSlice("error")
export const selectLoginPageErrorMessage = makeSelectLoginPageSlice("errorMessage")
export const selectLoginPageStartAnimation = makeSelectLoginPageSlice("startAnimation")
export const selectLoginPageEndAnimation = makeSelectLoginPageSlice("endAnimation")
export const selectLoginPageReverseAnimation = makeSelectLoginPageSlice("reverseAnimation")
export const selectLoginPageNextUrl = makeSelectLoginPageSlice("nextUrl")
export const selectLoginIsLoggedIn = makeSelectLoginPageSlice("isLoggedIn")
export const selectLoginIsFetchingAuthentication = makeSelectLoginPageSlice("isFetchingAuthentication")
export const selectLoginHasReceivedAuthentication = makeSelectLoginPageSlice("hasReceivedAuthentication")
export const selectLoginHasInitializedContext = makeSelectLoginPageSlice("hasInitializedContext")
export const selectSSOUrl = makeSelectLoginPageSlice("ssoUrl")
export const selectIsDoingMobileSSO = makeSelectLoginPageSlice("isDoingMobileSSO")

export const selectLoginTypeEnum = createSelector(
    selectLoginPageCurrentStep,
    (currentStep) => loginTypeLookup("value", currentStep) || LoginType.invalidLogin,
)

export const selectPasswordStrengthSecure = createSelector(
    makeLoginPageFormValueSelector("passwordStrength"),
    (passwordStrength) => passwordStrength >= 2, // Arbitrary score we decided is strong enough for a password
)

// Boolean specifying if should show password strength warning on mobile
export const selectShowPasswordStrengthWarning = createSelector(
    makeLoginPageFormValueSelector("password"),
    selectPasswordStrengthSecure,
    // If password is defined, return true if strength is not secure
    // If password is not defined, return false
    (password, passwordStrengthSecure) => (password ? !passwordStrengthSecure : false),
)

export const selectResetPasswordForm = createSelector(selectLoginTypeEnum, (loginTypeEnum) =>
    // Determines if on a reset password page
    Boolean(
        loginTypeEnum.value === LoginType.resetPassword.value ||
            loginTypeEnum.value === LoginType.resetPasswordFail.value,
    ),
)

export const selectResetPasswordValid = createSelector(
    makeLoginPageFormValueSelector("password"),
    makeLoginPageFormValueSelector("newPassword"),
    selectPasswordStrengthSecure,
    (password, newPassword, passwordStrengthSecure) =>
        Boolean(password && newPassword && password === newPassword && passwordStrengthSecure),
)

// To show password strength component on web app
export const selectShowPasswordStrength = createSelector(
    selectResetPasswordForm,
    makeLoginPageFormValueSelector("password"),
    (resetPasswordForm, password) => resetPasswordForm && Boolean(password),
)

export const selectSubmitButtonDisabled = createSelector(
    selectResetPasswordForm,
    selectResetPasswordValid,
    (resetPasswordForm, resetPasswordValid) =>
        // Determines if submit button should be disabled (show display disabled styling)
        resetPasswordForm &&
        // If form is valid (both passwords match, password is secure enough)
        !resetPasswordValid,
)

export const LoginPageSelectors = createStructuredSelector({
    username: makeLoginPageFormValueSelector("username"),
    password: makeLoginPageFormValueSelector("password"),
    newPassword: makeLoginPageFormValueSelector("newPassword"),
    twoFactorPassword: makeLoginPageFormValueSelector("twoFactorPassword"),
    shouldShowPasswordStrength: selectShowPasswordStrength,
    resetPasswordValid: selectResetPasswordValid,
    currentStep: selectLoginPageCurrentStep,
    submitButtonDisabled: selectSubmitButtonDisabled,
    loading: selectLoginPageLoading,
    error: selectLoginPageError,
    startAnimation: selectLoginPageStartAnimation,
    endAnimation: selectLoginPageEndAnimation,
    reverseAnimation: selectLoginPageReverseAnimation,
    nextUrl: selectLoginPageNextUrl,
    errorMessage: selectLoginPageErrorMessage,
})

export const MobileLoginPageSelectors = createStructuredSelector({
    username: makeLoginPageFormValueSelector("username"),
    password: makeLoginPageFormValueSelector("password"),
    newPassword: makeLoginPageFormValueSelector("newPassword"),
    twoFactorPassword: makeLoginPageFormValueSelector("twoFactorPassword"),
    resetPasswordValid: selectResetPasswordValid,
    showPasswordStrengthWarning: selectShowPasswordStrengthWarning,
    currentStep: selectLoginPageCurrentStep,
    loginTypeEnum: selectLoginTypeEnum,
    submitButtonDisabled: selectSubmitButtonDisabled,
    loading: selectLoginPageLoading,
    error: selectLoginPageError,
    isLoggedIn: selectLoginIsLoggedIn,
    hasReceivedAuthentication: selectLoginHasReceivedAuthentication,
    isFetchingAuthentication: selectLoginIsFetchingAuthentication,
    hasInitializedContext: selectLoginHasInitializedContext,
    reverseAnimation: selectLoginPageReverseAnimation,
    startAnimation: selectLoginPageStartAnimation,
    endAnimation: selectLoginPageEndAnimation,
    nextUrl: selectLoginPageNextUrl,
    isDoingMobileSSO: selectIsDoingMobileSSO,
})

export const LoginWebViewSelectors = createStructuredSelector({
    ssoUrl: selectSSOUrl,
})

export const PaidSearchDemoFormConnection = createStructuredSelector({
    formValues: selectPaidSearchFormValues,
    validationDict: selectPaidSearchFormValidation,
})
