import { generateStaticUrl } from "imports/desktopHelperFunctions"

const baseSeoValues = {
    title: "Quorum | Best-in-Class Public Affairs Software",
    baseDescription: "Quorum is software built for public affairs, providing one place for stakeholder engagement, legislative tracking, and grassroots advocacy.",
    socialDescription: "Quorum is software built for public affairs, providing one place for stakeholder engagement, legislative tracking, and grassroots advocacy.",
    keyWords: "legislative tracking, legislation, politics, congress, lobbying, statistics, legislative strategy, government relations, big data, policy, data, bill, vote, CQ, politics, analysis, government, Bloomberg, committee",
    socialBannerPath: generateStaticUrl("apps/marketing_site/images/hero_images/resized/main_banner.jpg"),
    faviconPath: generateStaticUrl("common/favicon.ico"),
}

export default baseSeoValues
