const usernameInputForm = {
    key: 1,
    fieldName: "username",
    fieldLabel: "Username or email",
    formType: "text",
    errorMessage: "That username/email and password combo is wrong",
}

const passwordInputForm = {
    key: 2,
    fieldName: "password",
    fieldLabel: "Password",
    formType: "password",
}

const emailInputForm = {
    key: 1,
    fieldName: "username",
    fieldLabel: "Email Address",
    formType: "text",
}

const twoFactorInputForm = {
    key: 1,
    placeholder: "Enter code",
    fieldName: "twoFactorPassword",
    fieldLabel: "Two-Factor Code",
    formType: "text",
    errorMessage: "That code is incorrect",
    shortInput: true,
}

const resetPasswordInputForms = [
    {
        key: 1,
        placeholder: "Enter new password",
        fieldName: "password",
        formType: "password",
        fieldLabel: "New Password",
        passwordStrength: true,
    },
    {
        key: 2,
        placeholder: "Confirm new password",
        fieldName: "newPassword",
        fieldLabel: "New Password Again",
        formType: "password",
    },
]

// These values are the same as the LoginType enums.
// If you are making any changes to LoginTypeValues you will also need to change the LoginType enums
export const LoginTypeValues = {
    invalidLogin: 1,
    blackListedBrowser: 2,
    success: 3,
    login: 4,
    unsupportedBrowserLogin: 5,
    password: 6,
    passwordBeforeTwoFactor: 7,
    twoFactor: 8,
    forgotPassword: 9,
    forgotPasswordSent: 10,
    ipNotAllowed: 11,
    limitedLoginError: 12,
    trialExpired: 13,
    loggedOut: 14,
    resetPassword: 15,
    resetPasswordFail: 16,
    resetPasswordSuccess: 17,
    invalidRegistrationLink: 18,
    maxMembersExceeded: 19,
    forgotPasswordInvalidEmail: 20,
    singleSignOn: 21,
    invalidSingleSignOn: 22,
    invalidTwoFactor: 23,
}
export const LoginType = {
    // value: Same value as the LoginType enum in quorum/types
    // metaTitle: Optional value, will overwrite 'Login' as the page title
    // bannerMessage: A message describing the step in the process. If error == true, have different styling. Try to keep the main banner messages two lines
    // showForm: Determine if form will have an input field and submit button
    // inputForms: Array of input fields
    // key: Key for React diffing
    // placeholder: text inside input field
    // fieldName: Key of store state
    // store.getState().marketing.get('loginPage').get('formValues').get(fieldName)
    // formType: Type of input field, "text" or "password"
    // previousFormValue: Show previous form before last submission
    // buttonText: Text on submit button
    // postUrl: API endpoint to POST to. If undefined or empty string, will not send POST request
    // pathName: The url of the current page
    // canNavigate: flag to specify if a user should be able to navigate straight to the url.
    // For example, users should be able to navigate to /recover/ for the recovery form.
    // But they should not be able to visit /login/password/ for the password form
    // showForgotPasswordLink: Boolean specifying if users should be able to see the link to the 'forgot password' form
    // showSSOlink: Boolean specifying if users should be able to see the link to the 'single sign on' page
    invalidLogin: {
        value: 1,
        bannerMessage: {
            error: true,
            text: "Your username/email address or password was incorrect. Please recheck and try again.",
            mobileText: "Incorrect login information.<br />Please try again.",
            subtitle: "Something went wrong!",
            showForgotPasswordLink: true,
        },
        showForm: true,
        inputForms: [usernameInputForm, passwordInputForm],
        buttonText: "Log in",
        postUrl: "/login/",
        pathName: "/login/invalid/",
        showForgotPasswordLink: true,
        showSSOLink: true,
    },
    blacklistedBrowser: {
        value: 2,
        bannerMessage: {
            error: true,
            text: 'Your current browser is not designed for the modern web, and many of Quorum\'s innovative features will not work on this browser. Quorum is best experienced on Google\'s free browser <a href="https://www.google.com/intl/en_us/chrome/browser/">Chrome</a>. Please download or switch to Chrome.</p><p>Email <a href="mailto:support@quorum.us">support@quorum.us</a> with any questions.',
        },
        showForm: false,
        pathName: "/login/blacklisted/",
    },
    success: {
        value: 3,
        bannerMessage: {
            error: false,
            text: "Successfully logged in!<br />Now redirecting...",
        },
        showForm: false,
        pathName: "/home/",
    },
    login: {
        value: 4,
        showForm: true,
        bannerMessage: {
            error: false,
            text: `Not a customer yet? <a href=\"https://www.quorum.us/homepage\">Learn why top public affairs teams choose Quorum</a>`,
            mobileText: "Welcome to Quorum!<br />Please log in to get started.",
            subtitle: "Welcome back to Quorum!",
        },
        inputForms: [usernameInputForm, passwordInputForm],
        buttonText: "Log in",
        postUrl: "/login/",
        pathName: "/login/",
        showForgotPasswordLink: true,
        showSSOLink: true,
    },
    unsupportedBrowserLogin: {
        value: 5,
        bannerMessage: {
            error: true,
            text: 'Your current browser will not support many of Quorum\'s innovative features. Quorum is best experienced on Google\'s free browser <a href="https://www.google.com/intl/en_us/chrome/browser/">Chrome</a>. Please download or switch to Chrome.</p><p>Email <a href="mailto:support@quorum.us">support@quorum.us</a> with any questions.',
        },
        showForm: true,
        inputForms: [usernameInputForm, passwordInputForm],
        buttonText: "Log in",
        postUrl: "/login/",
        pathName: "/login/unsupported/",
        showForgotPasswordLink: true,
        showSSOLink: true,
    },
    password: {
        value: 6,
        showForm: true,
        bannerMessage: {
            error: false,
            text: "Welcome to Quorum!<br />Please enter your password below.",
            mobileText: "Welcome to Quorum!<br />Please enter your password.",
        },
        inputForms: [
            {
                key: 1,
                placeholder: "Enter your password here...",
                fieldName: "password",
                formType: "password",
            },
        ],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Submit",
        postUrl: "/login/",
        pathName: "/login/password/",
        showForgotPasswordLink: true,
    },
    // This is the password form for users with Two Factor Enabled. After they submit this form, they are navigated to the Two Factor Form. Upon completion of that form, we POST to the endpoint with username, password, and two factor token
    passwordBeforeTwoFactor: {
        value: 7,
        showForm: true,
        bannerMessage: {
            error: false,
            text: "Welcome to Quorum!<br />Please enter your password below.",
            mobileText: "Welcome to Quorum!<br />Please enter your password.",
        },
        inputForms: [
            {
                key: 1,
                placeholder: "Enter your password here...",
                fieldName: "password",
                formType: "password",
            },
        ],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Submit",
        postUrl: "",
        pathName: "/login/password/",
        showForgotPasswordLink: true,
    },
    twoFactor: {
        value: 8,
        bannerMessage: {
            error: false,
            text: "Using the Google Authenticator app, enter the 6 digit code to authenticate your log in.",
            mobileText: "Enter your two-factor authentication code.",
        },
        showForm: true,
        inputForms: [twoFactorInputForm],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Log in",
        postUrl: "/login/",
        pathName: "/login/two-factor/",
        showForgotPasswordLink: false,
    },
    forgotPassword: {
        value: 9,
        metaTitle: "Recover",
        showForm: true,
        bannerMessage: {
            error: false,
            text: "Enter your email to reset your password or view your username details.",
            mobileText: "Enter your email address to reset your password",
        },
        inputForms: [emailInputForm],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Send Reset Email",
        mobileButtonText: "Send Email",
        postUrl: "/recover/reset/checkemail/",
        pathName: "/recover/",
        canNavigate: true,
        shouldFadeIn: true,
    },
    forgotPasswordSent: {
        value: 10,
        bannerMessage: {
            error: false,
            text: "Hold tight!<br/>If we found an active account with that email, a reset link with your username details will be sent!",
            mobileText: "If we found an active account, we sent you a link.<br />Check your inbox!",
        },
        showForm: false,
        pathName: "/login/sent/",
        buttonText: "Return to Login",
        icon: "paper-plane",
        previousFormValue: LoginTypeValues.login,
    },
    ipNotAllowed: {
        value: 11,
        bannerMessage: {
            error: true,
            text: "Incorrect IP Address",
        },
        showForm: false,
        pathName: "/login/ip-not-allowed/",
    },
    limitedLoginError: {
        value: 12,
        bannerMessage: {
            error: true,
            text: "You've now completed the registration process but the organization that created your account has not assigned you to any projects yet. Please check back later.",
        },
        showForm: false,
        pathName: "/login/limited-login/",
    },
    trialExpired: {
        value: 13,
        bannerMessage: {
            error: true,
            text: 'Your trial is expired. We hope you have enjoyed using Quorum during your trial. If you would like to purchase the full product, please contact us at <a href="mailto: sales@quorum.us">sales@quorum.us</a> for more information.',
        },
        showForm: false,
        icon: "hourglass",
        pathName: "/login/trial-expired/",
        previousFormValue: LoginTypeValues.login,
    },
    // The page the user is redirected to after logging out
    loggedOut: {
        value: 14,
        showForm: true,
        bannerMessage: {
            error: false,
            text: "You have been<br />successfully logged out.",
        },
        inputForms: [usernameInputForm, passwordInputForm],
        buttonText: "Log in",
        postUrl: "/login/",
        pathName: "/logout/",
        canNavigate: true,
        showForgotPasswordLink: true,
        showSSOLink: true,
    },
    resetPassword: {
        value: 15,
        metaTitle: "Recover",
        showForm: true,
        bannerMessage: {
            error: false,
            text: "Enter a new password below to change your account information.",
        },
        inputForms: resetPasswordInputForms,
        buttonText: "Reset",
        postUrl: "/recover/reset/change/",
        pathName: "/recover/reset/",
    },
    resetPasswordFail: {
        value: 16,
        metaTitle: "Recover",
        showForm: true,
        bannerMessage: {
            error: true,
            text: "We're sorry, there has been an error.<br />Please make sure the passwords match.",
            mobileText: "We're sorry, there's been an error.<br />Please make sure the passwords match.",
        },
        inputForms: resetPasswordInputForms,
        buttonText: "Reset",
        postUrl: "/recover/reset/change/",
        pathName: "/recover/reset/",
    },
    resetPasswordSuccess: {
        value: 17,
        showForm: true,
        bannerMessage: {
            error: false,
            text: "Password has been successfully changed!<br />Enter your username/email below to login!",
        },
        inputForms: [usernameInputForm, passwordInputForm],
        buttonText: "Login",
        postUrl: "/login/lookup/",
        pathName: "/login/",
    },
    invalidRegistrationLink: {
        value: 18,
        showForm: false,
        bannerMessage: {
            error: true,
            text: "You've stumbled upon a broken or expired link. That's some super sleuthing!",
        },
        canNavigate: true,
        icon: "user-secret",
        pathName: "/login/invalid-registration-link/",
        previousFormValue: LoginTypeValues.login,
    },
    maxMembersExceeded: {
        value: 19,
        showForm: false,
        bannerMessage: {
            error: true,
            text: `We're sorry, your organization has exceeded the maximum number of accounts. Please contact us at <a href="mailto:support@quorum.us">support@quorum.us</a>.`,
        },
        canNavigate: true,
        pathName: "/login/max-members-exceeded/",
    },
    forgotPasswordInvalidEmail: {
        value: 20,
        metaTitle: "Recover",
        showForm: true,
        bannerMessage: {
            error: true,
            text: "Please enter a valid email address",
            mobileText: "Please enter a valid email address",
        },
        inputForms: [
            {
                ...emailInputForm,
                errorMessage: "Please enter a valid email address.",
            },
        ],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Send Reset Email",
        mobileButtonText: "Send Email",
        postUrl: "/recover/reset/checkemail/",
        pathName: "/recover/",
        canNavigate: false,
        shouldFadeIn: false,
    },
    singleSignOn: {
        value: 21,
        showForm: true,
        bannerMessage: {
            error: false,
            text: "Please enter your username or email to use Single Sign On (SSO). SSO is only available to companies who enabled it with Quorum.",
            mobileText:
                "Please enter your username or email to use Single Sign On (SSO). SSO is only available to comapnies who enabled it with Quorum.",
        },
        inputForms: [
            {
                ...usernameInputForm,
                placeholder: "Enter your username",
            },
        ],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Next",
        postUrl: "/login/lookup_sso/",
        pathName: "/login/single-sign-on/",
        showForgotPasswordLink: false,
    },
    invalidSingleSignOn: {
        value: 22,
        showForm: true,
        bannerMessage: {
            error: true,
            text: "We're sorry, there has been an error.<br />Check that your username or email address is correct and try signing in again.",
            mobileText:
                "We're sorry, there has been an error.<br />Check that your username or email address is correct and try signing in again.",
        },
        inputForms: [
            {
                ...usernameInputForm,
                placeholder: "Enter your username",
            },
        ],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Next",
        postUrl: "/login/lookup_sso/",
        pathName: "/login/invalid-single-sign-on/",
        showForgotPasswordLink: false,
    },
    invalidTwoFactor: {
        value: 23,
        bannerMessage: {
            error: true,
            text: "The authentication code was incorrect. Use the Google Authenticator app and try again.",
            mobileText: "The authentication code was incorrect. Use the Google Authenticator app and try again.",
        },
        showForm: true,
        inputForms: [twoFactorInputForm],
        previousFormValue: LoginTypeValues.login,
        buttonText: "Log in",
        postUrl: "/login/",
        pathName: "/login/invalid-two-factor/",
        showForgotPasswordLink: false,
    },
    resetPasswordTwoFactor: {
        value: 24,
        bannerMessage: {
            error: false,
            text: "Using the Google Authenticator app, enter the 6 digit code to authenticate your account.",
            mobileText: "Enter your two-factor authentication code.",
        },
        showForm: true,
        inputForms: [twoFactorInputForm],
        previousFormValue: LoginTypeValues.resetPassword,
        buttonText: "Log in",
        postUrl: "/recover/reset/change/",
        pathName: "/recover/reset/",
        showForgotPasswordLink: false,
    },
    invalidResetPasswordTwoFactor: {
        value: 25,
        bannerMessage: {
            error: true,
            text: "The authentication code was incorrect. Use the Google Authenticator app and try again.",
            mobileText: "The authentication code was incorrect. Use the Google Authenticator app and try again.",
        },
        showForm: true,
        inputForms: [twoFactorInputForm],
        previousFormValue: LoginTypeValues.resetPassword,
        buttonText: "Reset",
        postUrl: "/recover/reset/change/",
        pathName: "/recover/reset/invalid-two-factor/",
        showForgotPasswordLink: false,
    },
}

export const loginTypeLookup = (key, value) => Object.values(LoginType).find((type) => type[key] === value)

export default LoginType
