import urls from "shared/marketing-website/constants/urls"

const MOBILE_WIDTH = 768
const USE_CASE_WIDTH = 1275
const GRASSROOTS_CUTOFF_WIDTH = 1495
const STAKEHOLDER_CUTOFF_WIDTH = 1400

const NAVIGATION_VALUES = [
    {
        title: "About",
        items: [
            {
                text: "Company",
                url: urls.aboutUs,
            },
            {
                text: "Careers",
                url: urls.careers,
            },
            {
                text: "Resources",
                url: urls.resources,
            },
            {
                text: "Products",
                url: urls.products,
            },
            {
                text: "Press",
                url: urls.press,
            },
            {
                text: "Security",
                url: urls.security,
            },
        ],
        // This is an array of browser width ranges in which this nav menu option will be render
        // The minimum screen size is first element, max is second
        // This will be rendered at all times
        displayRanges: [
            [0, Infinity],
        ]
    },

    // All three options are rendered for large screens
    {
        title: "Stakeholder Engagement",
        items: [],
        url: urls.stakeholderEngagement,
        displayRanges: [
            [0, MOBILE_WIDTH], // Render for mobile screen sizes
            [STAKEHOLDER_CUTOFF_WIDTH, Infinity], // Large screens
        ]
    },
    // Even smaller screen, only display 'Stakeholder' instead of 'Stakeholder Engagement'
    {
        title: "Stakeholder",
        items: [],
        url: urls.stakeholderEngagement,
        displayRanges: [
            [USE_CASE_WIDTH, STAKEHOLDER_CUTOFF_WIDTH],
        ]
    },

    {
        title: "Legislative Tracking",
        items: [],
        url: urls.legislativeTracking,
        displayRanges: [
            [0, MOBILE_WIDTH], // Render for mobile screen sizes
            [USE_CASE_WIDTH, Infinity], // Large screens
        ]
    },
    {
        title: "Grassroots Advocacy",
        items: [],
        url: urls.grassrootsAdvocacy,
        displayRanges: [
            [0, MOBILE_WIDTH], // Render for mobile screen sizes
            [GRASSROOTS_CUTOFF_WIDTH, Infinity], // Large screens
        ]
    },
    // As the browser width gets smaller, only display 'Grassroots' instead of full title
    {
        title: "Grassroots",
        items: [],
        url: urls.grassrootsAdvocacy,
        displayRanges: [
            [USE_CASE_WIDTH, GRASSROOTS_CUTOFF_WIDTH],
        ]
    },


    // This is rendered when the 3 options are too long to fit in the nav bar, but the browser is not a mobile size
    {
        title: "Solutions",
        items: [
            {
                text: "Stakeholder Engagement",
                url: urls.stakeholderEngagement,
            },
            {
                text: "Legislative Tracking",
                url: urls.legislativeTracking,
            },
            {
                text: "Grassroots Advocacy",
                url: urls.grassrootsAdvocacy,
            },
        ],
        displayRanges: [
            [MOBILE_WIDTH, USE_CASE_WIDTH],
        ]
    },
]

export default NAVIGATION_VALUES
