import React from "react"
import styled from "styled-components"

import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues"
import LOGIN_PAGE_VALUES from "shared/marketing-website/constants/loginPageValues"

import { QuorumTheme } from "app/static/frontend/design-constants"

const StyledFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;

    gap: 1rem;
    color: ${QuorumTheme.colors.gray[7]};
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;

    a {
        color: ${QuorumTheme.colors.gray[7]};
        text-decoration: underline;
    }
`

export const LoginPageFooter = () => {
    return (
        <StyledFooter>
            <span>
                Contact <a href="mailto:support@quorum.us">support@quorum.us</a> for help
            </span>
            <span>
                <a href={LOGIN_PAGE_VALUES.privacyPolicy} target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
                {" | "}
                <a href={LOGIN_PAGE_VALUES.termsOfUse} target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <br />
                {`${MARKETING_SITE_VALUES.currentYear} Quorum Analytics Inc.`}
            </span>
        </StyledFooter>
    )
}
