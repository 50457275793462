import axios from "axios"
import actionTypes from "shared/marketing-website/action-types"
import { LoginType, loginTypeLookup } from "shared/marketing-website/constants/loginType"
import { validateResponse } from "shared/imports/sharedHelperFunctions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"

// The amount of time it takes for one form to completely disappear/appear
const delayBetweenAnimations = 145

export const mountForm = (formKey) => (dispatch) =>
    dispatch({
        type: actionTypes.MOUNT_FORM,
        formKey,
    })

export const unmountForm = (formKey) => (dispatch) =>
    dispatch({
        type: actionTypes.UNMOUNT_FORM,
        formKey,
    })

export const clearForm = (formKey) => ({
    type: actionTypes.CLEAR_FORM,
    formKey,
})

export const clearFormError = (formKey) => ({
    type: actionTypes.CLEAR_FORM_ERROR,
    formKey,
})

export const clearSubmittedForm = (formKey) => (dispatch) => {
    dispatch(clearForm(formKey))
    dispatch(mountForm(formKey))
    dispatch({ type: actionTypes.SUBMIT_FORM_SUCCESS, formKey })
}

export const updateForm = (formKey, updateDict, validationDict) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_FORM_VALUES,
        updateDict,
        formKey,
    })
    if (validationDict) {
        dispatch({
            type: actionTypes.UPDATE_FORM_VALIDATION,
            validationDict,
            formKey,
        })
    }
}

export const resetForm = (formKey) => (dispatch) =>
    dispatch({
        type: actionTypes.SUBMIT_FORM_RESET,
        formKey,
    })

export const fetchBlogList =
    (suffix = undefined) =>
    (dispatch) => {
        dispatch({ type: actionTypes.GET_BLOG_LIST_START })
        return $.ajax({
            url: `/api/post/${suffix || ""}`,
            success: (response) => {
                dispatch({
                    type: actionTypes.GET_BLOG_LIST_SUCCESS,
                    posts: response.objects,
                    meta: response.meta,
                })
            },
            error: (e) => {
                console.error(e)
                dispatch({
                    type: actionTypes.GET_BLOG_LIST_FAIL,
                    error: e,
                })
            },
        })
    }

export const fetchNextBlogs = (url) => (dispatch) => {
    dispatch({ type: actionTypes.GET_NEXT_BLOGS_START })
    return $.ajax({
        url,
        success: (response) => {
            dispatch({
                type: actionTypes.GET_NEXT_BLOGS_SUCCESS,
                posts: response.objects,
                meta: response.meta,
            })
        },
        error: (e) => {
            console.error(e)
            dispatch({
                type: actionTypes.GET_NEXT_BLOGS_FAIL,
                error: e,
            })
        },
    })
}

export const fetchBlogDetail = (id) => (dispatch) => {
    dispatch({ type: actionTypes.GET_BLOG_DETAIL_START })
    return $.ajax({
        url: `/api/post/${id}/`,
        success: (response) => {
            dispatch({
                type: actionTypes.GET_BLOG_DETAIL_SUCCESS,
                post: response,
            })
        },
        error: (e) => {
            console.error(e)
            dispatch({
                type: actionTypes.GET_BLOG_DETAIL_FAIL,
                error: e,
            })
        },
    })
}

export const clearBlogDetail = () => ({
    type: actionTypes.CLEAR_BLOG_DETAIL,
})

export const submitForm =
    (url, data, formKey, shouldClearForm = true) =>
    (dispatch) => {
        return $.ajax({
            type: "POST",
            url,
            data,
            success: (result) => {
                if (shouldClearForm) {
                    dispatch(clearSubmittedForm(formKey))
                }
                try {
                    __adroll.record_user({ adroll_segments: "136605f1" }) // eslint-disable-line
                } catch (err) {
                    console.error("__adroll:", err)
                }
            },
            error: (result) => {
                console.log("err:", result)
            },
        })
    }

export const serializeQuery =
    (form = "resourceSearch") =>
    (dispatch, getState) => {
        const [...params] = getState().marketing.getIn([form, "formValues"]).entries()

        const paramString = params
            .map(([key, value]) => (value ? `${key}=${value}` : ""))
            .filter((param) => param)
            .join("&")

        return `?${paramString}`
    }

/**
 * This function ...
 * @name someName
 * @function
 * @param {Type} argument - a description
 * @returns {Type} - Something
 */
export const authenticateUserFromShared =
    (serverUrl = "") =>
    (dispatch) => {
        dispatch({ type: actionTypes.FETCH_AUTHENTICATION_START })
        return axios
            .get(`${serverUrl}/mobile_user_authentication/`)
            .then((response) => {
                const validatedResponse = validateResponse(response)
                dispatch({
                    type: actionTypes.SET_LOGGED_IN,
                    value: validatedResponse,
                })
                dispatch({
                    type: actionTypes.FETCH_AUTHENTICATION_SUCCESS,
                    value: true,
                })
                return validatedResponse
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FETCH_AUTHENTICATION_SUCCESS,
                    value: true,
                })
                return false
            })
    }

export const submitLoginForm =
    (url, serverUrl = "") =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.GET_NEXT_STEP_START,
        })

        const formValueLookup = (key) => getState().marketing.get("loginPage").get("formValues").get(key)
        const userCode = getState().marketing.get("loginPage").get("userCode")

        // The information we will post to the backend
        const data = {
            username: formValueLookup("username"),
            password: formValueLookup("password"),
            newPassword: formValueLookup("newPassword"),
            twoFactorPassword: formValueLookup("twoFactorPassword"),
            userCode,
            serverUrl,
        }

        // Axios requires the data be in `key=value` format
        // https://github.com/axios/axios/issues/886
        const stringData = Object.keys(data)
            .map((key) => `${key}=${encodeURIComponent(data[key])}`)
            .join("&")

        return axios
            .post(`${serverUrl}${url}`, stringData)
            .then((response) => {
                const LoginTypeEnum = loginTypeLookup("value", parseInt(response.data || response))
                // If the response.data from the api is a LoginType enum value, than we update the form with the next step in the login process
                if (LoginTypeEnum) {
                    // We start the animation
                    dispatch({
                        type: actionTypes.START_ANIMATION,
                    })
                    // Wait a delay for the starting animation to play out before proceeding
                    setTimeout(() => {
                        if (LoginTypeEnum.value === LoginType.resetPasswordSuccess.value) {
                            // Special case, if they have just changed their password,
                            // want to reset the form and have them enter their new credentials. Extra security
                            dispatch({
                                type: actionTypes.CLEAR_FORM,
                                formKey: "loginPage",
                            })
                        }
                        dispatch({
                            type: actionTypes.GET_NEXT_STEP_SUCCESS,
                            currentStep: parseInt(response.data || response),
                        })
                        // Play the ending animation
                        dispatch({
                            type: actionTypes.END_ANIMATION,
                        })
                    }, delayBetweenAnimations)
                } else {
                    // If the response.data is a url, then we redirect to it
                    dispatch({
                        type: actionTypes.REDIRECT_TO,
                        nextUrl: response.data || response,
                        headers: response.headers,
                    })
                    // throw the response up so that it can be properly redirected to
                    // on mobile
                    if (serverUrl !== "") {
                        throw response
                    }
                }
            })
            .catch((error) => {
                // case: mobile login
                if (serverUrl !== "" && !error.response && typeof error === "string") {
                    throw error
                } else {
                    if (
                        !(
                            // do not clear fields if we are moving to these invalid login states
                            (
                                error.response.data !== LoginType.invalidLogin.value &&
                                error.response.data !== LoginType.invalidSingleSignOn.value &&
                                error.response.data !== LoginType.invalidTwoFactor.value
                            )
                        )
                    ) {
                        // When receiving HttpResponseForbidden, clear form and set it to display the invalid login form
                        dispatch({
                            type: actionTypes.CLEAR_FORM,
                            formKey: "loginPage",
                        })
                    }
                    const { data: responseData } = error.response
                    const currentStep = (typeof responseData === "object" && responseData?.reason) || responseData
                    dispatch({
                        type: actionTypes.GET_NEXT_STEP_FAIL,
                        currentStep,
                    })
                    dispatch({
                        type: actionTypes.UPDATE_USER_CODE,
                        userCode,
                    })
                    dispatch({
                        type: actionTypes.FETCH_AUTHENTICATION_SUCCESS,
                        value: true,
                    })
                    dispatch({
                        type: actionTypes.SET_ERROR_MESSAGE,
                        errorMessage: (typeof responseData === "object" && responseData?.message) || "",
                    })
                }
            })
    }

export const updateLoginStep = (currentStep) => (dispatch) => {
    dispatch({
        // synchronous validation doesn't go through submitLoginForm, so we
        // need to check for error states here
        type:
            currentStep === LoginType.forgotPasswordInvalidEmail.value
                ? actionTypes.GET_NEXT_STEP_FAIL
                : actionTypes.GET_NEXT_STEP_SUCCESS,
        currentStep,
    })
}

export const updateUserCode = (userCode) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_USER_CODE,
        userCode,
    })
}

export const animateNextStep =
    (currentStep, reverseAnimation = false) =>
    (dispatch) => {
        // This will simulate the loading and start the animation
        dispatch({
            type: actionTypes.GET_NEXT_STEP_START,
            reverseAnimation,
        })
        dispatch({
            type: actionTypes.START_ANIMATION,
        })

        // After a delay to let the starting animation finish, we update the form and play the ending animation
        setTimeout(() => {
            dispatch({
                type: actionTypes.GET_NEXT_STEP_SUCCESS,
                currentStep,
            })
            dispatch({
                type: actionTypes.END_ANIMATION,
            })
        }, delayBetweenAnimations)

        // Set focus back on input field after animation
        const inputField = document.getElementById("login-page-input-field")
        if (inputField) {
            setTimeout(() => {
                inputField.focus()
                // The normal value for delayBetweenAnimations is the time it takes for the form to leave the boundaries of the form.
                // I am setting this timeout at twice that value to ensure that the animation has time to fully complete before focusing
            }, delayBetweenAnimations * 2)
        }
    }

export const animateNextStepMobile =
    (currentStep, reverseAnimation = false) =>
    (dispatch) => {
        // This will simulate the loading and start the animation
        dispatch({
            type: actionTypes.GET_NEXT_STEP_START,
            reverseAnimation,
        })
        dispatch({
            type: actionTypes.START_ANIMATION,
        })

        // After a delay to let the starting animation finish, we update the form and play the ending animation
        setTimeout(() => {
            dispatch({
                type: actionTypes.GET_NEXT_STEP_SUCCESS,
                currentStep,
            })
            dispatch({
                type: actionTypes.END_ANIMATION,
            })
        }, delayBetweenAnimations)
    }

export const fetchAvailablePositions = () => (dispatch) =>
    $.ajax({
        type: "GET",
        url: "https://api.greenhouse.io/v1/boards/quorum/jobs?content=true",
        success: (result) => {
            dispatch({ type: actionTypes.GET_AVAILABLE_POSITIONS, jobs: result.jobs })
        },
        error: (result) => {
            console.log("err:", result)
        },
    })

export const showDemoModal = (eventLabel) => (dispatch) => {
    dispatch({
        type: actionTypes.SHOW_REQUEST_DEMO_MODAL,
        eventLabel,
    })
}

export const hideDemoModal = () => (dispatch) => dispatch({ type: actionTypes.HIDE_REQUEST_DEMO_MODAL })
