const urls = {
    // Login pages
    login: "/login/",
    loginWithParams: "/login/:formType?/",
    logout: "/logout/",
    recover: "/recover/",
    reset: "/recover/reset/:userCode?/",
}

export default urls
