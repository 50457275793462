import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const LOGIN_PAGE_VALUES = {
    backgroundImagePath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/stakeholder_banner.jpg"),
    logoImgPath: generateStaticUrl("apps/marketing-website/images/login_logo_2.svg"),
    privacyPolicy: "https:\/\/www.quorum.us\/privacy-policy\/",
    termsOfUse: generateStaticUrl("apps/marketing-website/standard_terms_of_use.pdf"),
    unsupportedBrowsers: {
        "opera": {
            // Do not support this browser of any version
            blacklisted: true,
        },
        "chrome": {
            // Any version below minWarnVersion is blacklisted. Any version (including this version) between this and maxWarnVersion receives a warning
            minWarnVersion: 35,
            // Any version above maxWarnVersion (including this version) does not receive a warning
            maxWarnVersion: 41,
        },
        "iPad": {
            minWarnVersion: 3,
            maxWarnVersion: 5,
        },
        "safari": {
            minWarnVersion: 8,
            maxWarnVersion: 9,
        },
        "ie": {
            minWarnVersion: 10,
            // If does not have maxWarnVersion, will warn for all versions above minWarnVersion
        },
        "edge": {
            minWarnVersion: 13,
            maxWarnVersion: 15,
        },
        "firefox": {
            minWarnVersion: 48,
            maxWarnVersion: 53,
        },

    }
}

export default LOGIN_PAGE_VALUES
