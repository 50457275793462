import React from "react"
import PropTypes from "prop-types"

import "quorum/static/frontend/marketing-website/stylus/SocialMediaSidebar.styl"

const SocialMediaSidebar = ({ url, title, position }) => {
    const handleClick = socialMedia =>
        () => {
            gtag('event', window.location.pathname, {
                event_category: 'Social Share',
                event_label: `${socialMedia}_${position}`,
            })
        }

    const generateLink = (source, medium, type) =>
        `${encodeURIComponent(`${url}?utm_campaign=Sharing%20Buttons&utm_source=${source}&utm_medium=${medium}&utm_term=${window.location.pathname}&utm_content=${type}_${position}`)}`

    return (
        <div
            className={`social-media-sidebar-main ${position}`}
        >
            <a
                className="social-button marketing-social-twitter"
                href={`https://twitter.com/intent/tweet?url=${generateLink("twitter", "social", "twitter")}&text=${`${encodeURIComponent(title)}%20by%20@QuorumAnalytics:`}`}
                target="_blank"
                onClick={handleClick("Twitter")}
            >
                <i className="fa fa-twitter" />Tweet
            </a>
            <a
                className="social-button marketing-social-linkedin"
                href={`https://www.linkedin.com/shareArticle?&text=${`${encodeURIComponent(title)}%20by%20Quorum:%20${generateLink("linkedin", "social", "linkedin")}`}&url=${generateLink("linkedin", "social", "linkedin")}`}
                target="_blank"
                onClick={handleClick("LinkedIn")}
            >
                <i className="fa fa-linkedin" />Share
            </a>
            <a
                className="social-button marketing-social-facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${generateLink("facebook", "social", "facebook")}`}
                target="_blank"
                onClick={handleClick("Facebook")}
            >
                <i className="fa fa-facebook-square" />Share
            </a>
            <a
                className="social-button marketing-social-email"
                href={`mailto:?subject=Quorum:%20${encodeURIComponent(title)}&body=${`${encodeURIComponent(title)}%20by%20Quorum:%20${generateLink("email-share-button", "email", "email")}`}`}
                target="_top"
                onClick={handleClick("Email")}
            >
                <i className="fa fa-envelope" />Email
            </a>
        </div>
    )
}

SocialMediaSidebar.defaultProps = {
    url: window.location,
    title: "",
    position: "top",
}

SocialMediaSidebar.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    position: PropTypes.string,
}

export default SocialMediaSidebar
