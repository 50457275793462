import React from "react"
import PropTypes from "prop-types"

import {
    Row,
    Col,
    Button,
} from "react-bootstrap"
import classNames from "classnames"

import SegueLink from "middleware/SegueLink"

import VisibleSection from "quorum/static/frontend/marketing-website/components/VisibleSection"
import QuoteSection from "quorum/static/frontend/marketing-website/components/QuoteSection"
import BlogThumbnail from "quorum/static/frontend/marketing-website/components/BlogThumbnail"
import { scrollToRequestDemo, scrollToTop } from "quorum/static/frontend/marketing-website/constants/functions"

import "quorum/static/frontend/marketing-website/stylus/ProductShow.styl"

const reportSecondaryCTAAction = (label) => {
    window.gtag('event', window.location.pathname, {
        event_category: 'resources',
        event_label: label,
    })
}

const ProductShowBtnToolbar = ({
    customClassName,
    buttonAlignedRight,
    secondaryCTA = null,
    mobilePage = null,
    requestDemoForm,
    showDemoModal,
    multipleCtaButtons,
}) => {
    const ctaButton = (buttonProps) => {
        const secondaryCTAInfo = buttonProps ? buttonProps.secondaryCTA : secondaryCTA

        if (secondaryCTAInfo && secondaryCTAInfo.external) {
            // If the CTA is to an external link
            return (
                <a
                    href={secondaryCTAInfo.link}
                    onClick={() => reportSecondaryCTAAction(secondaryCTAInfo.label)}
                >
                    <Button
                        className="product-show-button btn-lg btn-primary"
                    >
                        {secondaryCTAInfo.label}
                    </Button>
                </a>
            )
        } else if (secondaryCTAInfo && secondaryCTAInfo.link) {
            // Cta links to an internal page
            return (
                <SegueLink
                    to={secondaryCTAInfo.link}
                    onClick={() => reportSecondaryCTAAction(secondaryCTAInfo.label)}
                >
                    <Button
                        className="product-show-button btn-lg btn-primary"
                    >
                        {secondaryCTAInfo.label}
                    </Button>
                </SegueLink>
            )
        } else if (requestDemoForm && showDemoModal) {
            return (
                <Button
                    onClick={() => showDemoModal("Product CTA")}
                    className="product-show-button btn-lg request-demo-button"
                >
                    Request Demo
                </Button>
            )
        } else {
            // No link specified. Clicking will scroll to the mobile download icons (if on mobile page)
            // Or the request demo form (for all other pages)
            return (
                <Button
                    onClick={mobilePage ? scrollToTop : scrollToRequestDemo}
                    className="product-show-button btn-lg"
                >
                    {
                        mobilePage
                            ? "Download Now"
                            : (secondaryCTAInfo && secondaryCTAInfo.label)
                                ? secondaryCTAInfo.label
                                : "Learn More"
                    }
                </Button>
            )
        }
    }

    return (
        <div
            className={classNames({
                "custom-btn-toolbar": true,
                "pull-right": buttonAlignedRight,
                [customClassName]: true
            })}
        >
            {
                multipleCtaButtons
                    ? multipleCtaButtons.map(buttonProps => ctaButton(buttonProps))
                    : ctaButton()
            }
        </div>
    )
}

ProductShowBtnToolbar.propTypes = {
    secondaryCTA: PropTypes.object,
    mobilePage: PropTypes.bool,
    requestDemoForm: PropTypes.bool,
    showDemoModal: PropTypes.func,
    customClassName: PropTypes.string.isRequired,
    buttonAlignedRight: PropTypes.bool.isRequired,
}

class ProductShow extends React.Component {

    renderTextSections = () => (
        <div className="section-text">
            {
                // If There is a link attached to the sectionMainHeader
                this.props.sectionMainHeader &&
                this.props.sectionMainHeaderUrl &&
                <h2 className="section-main-header">
                    <SegueLink
                        to={this.props.sectionMainHeaderUrl}
                        className="section-main-header-link"
                    >
                        {this.props.sectionMainHeader}
                    </SegueLink>
                </h2>
            }
            {
                // If There isn't a link attached to the sectionMainHeader,
                // allow dangerously setting inner html for superscripts/bold
                this.props.sectionMainHeader &&
                !this.props.sectionMainHeaderUrl &&
                <h2
                    className="section-main-header"
                    dangerouslySetInnerHTML={{ __html: this.props.sectionMainHeader }}
                />
            }
            {
                this.props.sectionLeadHeader &&
                <h3>
                    <span className="section-lead-header">
                        {this.props.sectionLeadHeader}
                    </span>
                </h3>
            }
            {
                this.props.sectionText &&
                <p
                    className="section-text-main"
                    dangerouslySetInnerHTML={{ __html: this.props.sectionText }}
                />
            }
            {
                this.props.sectionQuote &&
                <QuoteSection
                    quote={this.props.sectionQuote}
                    author={this.props.sectionQuoteAuthor}
                    authorTitle={this.props.sectionQuoteAuthorTitle}
                    logo={this.props.sectionQuoteLogo}
                    textAlignedLeft={this.props.textAlignedLeft}
                    specialStyles={this.props.specialStyles}
                />
            }
            {
                !this.props.removeSecondayCTA &&
                <ProductShowBtnToolbar
                    buttonAlignedRight={!this.props.textAlignedLeft}
                    customClassName={"hidden-sm hidden-xs custom-btn-toolbar-less-padding"}
                    secondaryCTA={this.props.secondaryCTA}
                    mobilePage={this.props.mobilePage}
                    requestDemoForm={this.props.requestDemoForm}
                    showDemoModal={this.props.showDemoModal}
                    multipleCtaButtons={this.props.multipleCtaButtons}
                />
            }
        </div>
    )

    renderProductSections = () => (
        <div
            className={classNames(
                "product-img-holder",
                { "product-video-holder": this.props.videoSrc }
            )}
        >
            {
                this.props.imgSrc &&
                <img
                    src={this.props.imgSrc}
                    alt={this.props.altText}
                    className="product-img"
                />
            }
            {
                // For now, these are only being utilized for the mobile-app page
                this.props.videoSrc &&
                this.props.backgroundImgSrc &&
                <div
                    style={{
                        backgroundImage: `url("${this.props.backgroundImgSrc}")`
                    }}
                    className="product-video-wrapper"
                >
                    <div className={this.props.videoClass}>
                        <iframe
                            title={this.props.altText}
                            className="product-video"
                            // rel=0: no related videos, playsinline: does not go to fullscreen on mobile
                            src={`${this.props.videoSrc}&rel=0&autoplay=1&mute=1&loop=1&controls=0&showinfo=0&playsinline=1`}
                            allow="autoplay; encrypted-media"
                        />
                    </div>
                </div>
            }
            {
                this.props.featuredPosts &&
                <Row>
                    {
                        this.props.posts.map((post, idx) =>
                            <BlogThumbnail
                                key={post.id}
                                id={post.id}
                                blogPostType={post.blog_post_type}
                                title={post.title}
                                imgPath={post.featured_image}
                                shortCode={post.shortcode}
                                logoImgPath={post.logo_image}
                                lgWidth={6}
                                mdWidth={12}
                                // Hide every other blog thumbnail for mobile screens
                                xsHidden={idx % 2 === 1}
                                fontSize={24}
                            />
                        )
                    }
                </Row>
            }
            {
                !this.props.removeSecondayCTA &&
                <ProductShowBtnToolbar
                    buttonAlignedRight={false}
                    customClassName={"hidden-lg hidden-md custom-btn-toolbar-more-padding"}
                    secondaryCTA={this.props.secondaryCTA}
                    mobilePage={this.props.mobilePage}
                    multipleCtaButtons={this.props.multipleCtaButtons}
                />
            }
        </div>
    )
    render() {
        return (
            <VisibleSection
                className="product-show-section-wrapper"
            >
                <Row
                    id={this.props.id}
                    className={classNames({
                        "section-holder": true,
                        "product-show-section-row": true,
                        "text-aligned-left": this.props.textAlignedLeft,
                        "text-aligned-right": !this.props.textAlignedLeft,
                        [this.props.theme]: this.props.theme,
                    })}
                >
                    <Col
                        lg={5}
                        md={5}
                        sm={10}
                        xs={10}
                        lgOffset={this.props.textAlignedLeft ? 1 : 0}
                        mdOffset={this.props.textAlignedLeft ? 1 : 0}
                        smOffset={1}
                        xsOffset={1}
                        mdPush={this.props.textAlignedLeft ? undefined : 6}
                        lgPush={this.props.textAlignedLeft ? undefined : 6}
                        className={classNames(
                            "section",
                            "section-left",
                            { "product-show-video": this.props.videoSrc }
                        )}
                    >
                        { this.renderTextSections() }
                    </Col>
                    <Col
                        lg={5}
                        md={5}
                        sm={10}
                        xs={10}
                        lgOffset={this.props.textAlignedLeft ? 0 : 1}
                        mdOffset={this.props.textAlignedLeft ? 0 : 1}
                        smOffset={1}
                        xsOffset={1}
                        mdPull={this.props.textAlignedLeft ? undefined : 5}
                        lgPull={this.props.textAlignedLeft ? undefined : 5}
                        className={classNames(
                            "section",
                            "section-right",
                            { "product-show-video": this.props.videoSrc }
                        )}
                    >
                        { this.renderProductSections() }
                    </Col>
                </Row>
            </VisibleSection>
        )
    }
}

ProductShow.defaultProps = {
    id: "",
    images: [],
    imgSrc: "",
    featuredPosts: false,
    removeSecondayCTA: false,
    requestDemoForm: false,
    sectionMainHeader: "",
    sectionMainHeaderUrl: "",
    sectionQuote: "",
    sectionQuoteAuthor: "",
    videoSrc: "",
    videoClass: "",
}

ProductShow.propTypes = {
    altText: PropTypes.string.isRequired,
    backgroundImgSrc: PropTypes.string,
    featuredPosts: PropTypes.bool,
    id: PropTypes.string,
    images: PropTypes.array,
    imgSrc: PropTypes.string,
    posts: PropTypes.object,
    removeSecondayCTA: PropTypes.bool,
    requestDemoForm: PropTypes.bool,
    sectionLeadHeader: PropTypes.string.isRequired,
    sectionMainHeader: PropTypes.string,
    sectionMainHeaderUrl: PropTypes.string,
    sectionText: PropTypes.string.isRequired,
    sectionQuote: PropTypes.string,
    sectionQuoteAuthor: PropTypes.string,
    sectionQuoteAuthorTitle: PropTypes.string,
    sectionQuoteLogo: PropTypes.string,
    showDemoModal: PropTypes.func,
    textAlignedLeft: PropTypes.bool.isRequired,
    theme: PropTypes.string,
    videoSrc: PropTypes.string,
    videoClass: PropTypes.string,
}

export default ProductShow
