import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues"
import urls from "shared/marketing-website/constants/urls"

const FOOTER_VALUES = {
    subFooter: {
        columns: [
            {
                header: "Solutions",
                items: [
                    {
                        title: "Stakeholder Engagement",
                        url: urls.stakeholderEngagement,
                    },
                    {
                        title: "Legislative Tracking",
                        url: urls.legislativeTracking,
                    },
                    {
                        title: "Grassroots Advocacy",
                        url: urls.grassrootsAdvocacy,
                    },
                ],
            },
            {
                header: "Products",
                headerUrl: urls.products,
                items: [
                    {
                        title: "Quorum Federal",
                        url: urls.federalProduct
                    },
                    {
                        title: "Quorum State",
                        url: urls.stateProduct,
                    },
                    {
                        title: "Quorum Local",
                        url: urls.localProduct,
                    },
                    {
                        title: "Quorum EU",
                        url: urls.euProduct,
                    },
                    {
                        title: "Quorum Grassroots",
                        url: urls.grassrootsAdvocacy,
                    },
                    {
                        title: "Quorum Stakeholder",
                        url: urls.stakeholderEngagement,
                    },
                    {
                        title: "Quorum International",
                        url: urls.internationalProduct,
                    },
                ],
            },
            {
                header: "About Us",
                items: [
                    {
                        title: "Company",
                        url: urls.aboutUs,
                    },
                    {
                        title: "Resources",
                        url: urls.resources,
                    },
                    {
                        title: "Careers",
                        url: urls.careers,
                    },
                    {
                        title: "Press",
                        url: urls.press,
                    },
                    {
                        title: "Security",
                        url: urls.security,
                    },
                ],
            },
            {
                header: "Other",
                items: [
                    {
                        title: "Mobile App",
                        url: urls.mobileProduct,
                    },
                    {
                        title: "Desktop App",
                        url: urls.desktopProduct,
                    },
                    {
                        title: "Regulations",
                        url: urls.regulationsProduct,
                    },
                    {
                        title: "Press Kit",
                        url: MARKETING_SITE_VALUES.pressKit,
                        external: true,
                    },
                    {
                        title: "Privacy Policy",
                        url: MARKETING_SITE_VALUES.privacyPolicy,
                        external: true,
                    },
                ],
            },
        ],
    },
}

export default FOOTER_VALUES
