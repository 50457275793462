export function segue(nextUrl) {
    return () => {
        // Update the 'rel=canonical' link tag as user segues throughout the marketing site
        const relCanonical = document.querySelector("head > link[rel=canonical]")
        if (nextUrl && relCanonical) {
            // If they are on the homepage, set canonical link to base url. Else, set canonical to absolute url
            if (nextUrl === "/homepage/") {
                relCanonical.href = `https://www.quorum.us/`
            } else {
                relCanonical.href = `https://www.quorum.us${nextUrl}`
            }
        }
        window.history.pushState({}, "", nextUrl)
    }
}
