import React from "react"

import "quorum/static/frontend/marketing-website/stylus/HeroImageBottomBanner.styl"

const HeroImageBottomBanner = (props) => (
    <div className="hero-image-bottom-banner">
        <div className="hero-image-bottom-banner-centered">
        </div>
    </div>
)

export default HeroImageBottomBanner
