import React from "react"
import PropTypes from "prop-types"
import { Col, FormGroup, FormControl } from "react-bootstrap"

const MultiLineInput = (props) => (
    <Col md={12} sm={12} xs={12}>
        <FormGroup validationState={props.getValidationState(props.getValue(props.formValues))}>
            <FormControl
                as="textarea"
                placeholder={props.placeHolderText}
                value={props.getValue(props.formValues)}
                rows={props.rows}
                onChange={props.handleChange}
            />
        </FormGroup>
    </Col>
)

MultiLineInput.defaultProps = {
    rows: 6,
}

MultiLineInput.propTypes = {
    // Props from FormInput
    getValidationState: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    placeHolderText: PropTypes.string.isRequired,
    rows: PropTypes.number,
}

export default MultiLineInput
