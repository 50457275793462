import React from "react"
import PropTypes from "prop-types"
import { Col, Form, FormGroup, InputGroup, FormControl } from "react-bootstrap"

const SelectInput = (props) => {
    return (
        <Col
            lg={props.explicitColLg || 6}
            md={props.explicitColMd || 6}
            sm={props.explicitColSm || 12}
            xs={props.explicitColXs || 12}
        >
            <FormGroup controlId="formControlsSelect">
                <FormControl as="select" placeholder={props.placeHolderText} onChange={props.handleChange}>
                    <option selected="true" disabled="disabled">
                        {props.placeHolderText}
                    </option>
                    <option value="Federal">Federal</option>
                    <option value="States">States</option>
                    <option value="Grassroots">Grassroots</option>
                    <option value="Stakeholder">Stakeholder</option>
                    <option value="EU">EU</option>
                    <option value="International">International</option>
                </FormControl>
            </FormGroup>
        </Col>
    )
}

SelectInput.defaultProps = {
    explicitColLg: 0,
    explicitColMd: 0,
    explicitColSm: 0,
    explicitColXs: 0,
}

SelectInput.propTypes = {
    // Props from FormInput
    getValidationState: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    explicitColLg: PropTypes.number,
    explicitColMd: PropTypes.number,
    explicitColSm: PropTypes.number,
    explicitColXs: PropTypes.number,

    // Props from FormInput definition
    fontAwesomeIcon: PropTypes.string.isRequired,
    placeHolderText: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
}

export default SelectInput
