import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"
import { generateStaticUrl } from "imports/desktopHelperFunctions"

const INTERNATIONAL_PRODUCT_VALUES = {
    meta: {
        title: "International",
        description: "Gather intelligence, identify trends, and communicate with key stakeholders so your global public affairs team can work together better to achieve your goals.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                backgroundPositionY: "50%",
                imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/international/international_header.jpg"),
                subtitle: "Work together better to achieve your goals by gathering intelligence, identifying trends, and communicating with key stakeholders.",
                title: "Quorum International",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                altText: "have-everyone-in-one-place",
                id: "have-everyone-in-one-place",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/international/everyone_one_place2.png"),
                key: "have-everyone-in-one-place",
                sectionMainHeader: "Have Everyone You Need in One Place",
                sectionLeadHeader: "Find the Right Information About Policymakers",
                sectionText: "Leverage a comprehensive database of legislators, officials, and other stakeholders in countries around the world, complete with their background and contact info, social media posts, and customizable bios and fields.",
                textAlignedLeft: true,
                secondaryCTA: {
                    link: "/resources/expand-impact-global-government-affairs/455/",
                    label: "Explore Three Steps"
                },
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "real-time-alerts",
                id: "real-time-alerts",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/international/ear_to_the_conversation.png"),
                key: "real-time-alerts",
                sectionMainHeader: "Keep an Ear to the Conversation",
                sectionLeadHeader: "Get Real-Time Alerts on Your Issues",
                sectionText: "Know in real-time whenever a policymaker or relevant stakeholder mentions your issues or your organization on social media. Use Quorum’s database of tens of millions of Tweets, Facebook posts, and YouTube videos from elected officials to easily discover insights and trends.",
                textAlignedLeft: false,
                theme: "section-blue",
                secondaryCTA: {
                    link: "/case-studies/crisis-communications-trump-tweet/147/",
                    label: "Crisis Comms in Action"
                },
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "communicate-your-message",
                id: "communicate-your-message",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/international/communicate_your_message.png"),
                key: "communicate-your-message",
                sectionMainHeader: "Communicate Your Message",
                sectionLeadHeader: "Send Personalized Bulk Email Updates",
                sectionText: "Use Outbox—Quorum’s integrated email tool—alongside up-to-date contact information to proactively get your message in front of legislators, officials, and stakeholders so you can more easily manage your relationships from afar.",
                textAlignedLeft: true,
                secondaryCTA: {
                    link: "/case-studies/execute-stakeholder-strategy/244/",
                    label: "How Coca-Cola Made Proactive Outreach Easier"
                },
            },
        },
        {
            component: ProductShow,
            props: {
                altText: "stay-on-top-of-conversation",
                id: "prove-your-roi",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/international/prove_your_roi.png"),
                key: "prove-your-roi",
                sectionMainHeader: "Prove Your ROI",
                sectionLeadHeader: "Measure Success and Create Custom Reports",
                sectionText: "Benchmark the impact of your team by the issues you work on, level of engagement with stakeholders, how you drive the conversation, and more. Create advanced, exportable reports of team activity to prove your team’s ROI on the bottom line.",
                textAlignedLeft: false,
                theme: "section-grey",
                secondaryCTA: {
                    link: "/resources/five-strategies-measuring-roi/431/",
                    label: "5 Tactics to Measure ROI"
                },
            },
        },
    ]
}

export default INTERNATIONAL_PRODUCT_VALUES
