import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Helmet } from "react-helmet"
import { Row, Col } from "react-bootstrap"

import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import BlogDetailDownloadForm from "quorum/static/frontend/marketing-website/containers/BlogDetailDownloadForm"

import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"

import {
    stripHtml,
    getPreviewFromHtmlContent,
    metadataTitle,
    embedTweets,
} from "quorum/static/frontend/marketing-website/constants/functions"

import "quorum/static/frontend/marketing-website/stylus/BlogDetailDownload.styl"

const mapStateToProps = (state, props) => ({
    title: selectors.makeBlogIndividualSelector("title")(state),
    subtitle: selectors.makeBlogIndividualSelector("subtitle")(state),
    description: selectors.makeBlogIndividualSelector("description")(state),
    content: selectors.makeBlogIndividualSelector("content")(state),
    hubspot_cta: selectors.makeBlogIndividualSelector("hubspot_cta")(state),
    featuredImage: selectors.makeBlogIndividualSelector("featured_image")(state),
    whitePaper: selectors.makeBlogIndividualSelector("white_paper")(state),
    logoImgPath: selectors.makeBlogIndividualSelector("logo_image")(state),
})

class BlogDetailDownload extends React.Component {
    componentDidMount() {
        this.props.fetchBlogDetail(this.props.params.id)
        this.props.mountForm(this.props.formKey)
    }

    componentDidUpdate() {
        embedTweets()
    }

    componentWillUnmount() {
        this.props.unmountForm(this.props.formKey)
        this.props.resetForm(this.props.formKey)
    }

    render() {
        return (
            <div
                className="blog-download-main"
            >
                <Helmet>
                    <title>
                        {metadataTitle(this.props.title)}
                    </title>
                    <meta
                        name="description"
                        content={getPreviewFromHtmlContent(this.props.description) || ""}
                    />
                    {/* Facebook */}
                    <meta
                        property="og:title"
                        content={metadataTitle(this.props.title)}
                    />
                    <meta
                        property="og:description"
                        content={getPreviewFromHtmlContent(this.props.description) || ""}
                    />
                    <meta
                        property="og:image"
                        content={this.props.featuredImage}
                    />
                    {/* Twitter */}
                    <meta
                        name="twitter:title"
                        content={metadataTitle(this.props.title)}
                    />
                    <meta
                        name="twitter:description"
                        content={getPreviewFromHtmlContent(this.props.description) || ""}
                    />
                    <meta
                        name="twitter:image"
                        content={this.props.featuredImage}
                    />
                    {/* This Twitter widget "scans the DOM on execution, converting blockquote.twitter-tweet elements into fully-rendered embedded Tweets." */}
                    {/* https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/overview */}
                    <script
                        async
                        src="https://platform.twitter.com/widgets.js"
                        charSet="utf-8"
                    />
                </Helmet>
                <HeroImage
                    imgPath={this.props.featuredImage}
                    title={this.props.title ? stripHtml(this.props.title) : ""}
                    subtitle={this.props.subtitle}
                    customStyleClassName={"download-page-hero-img"}
                    backgroundPositionY={"0%"}
                    logoImgPath={this.props.logoImgPath}
                />
                <Row
                    className="blog-download-content"
                >
                    <Col
                        lg={6}
                        lgOffset={1}
                        md={10}
                        mdOffset={1}
                        sm={10}
                        smOffset={1}
                        xs={10}
                        xsOffset={1}
                        className="blog-download-text"
                    >
                        <div
                            className="blog-download-description"
                            dangerouslySetInnerHTML={{ __html: this.props.description }}
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: this.props.content }}
                            className="section-text-main"
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: this.props.hubspot_cta }}
                            className="blog-download-hubspot-cta"
                        />
                    </Col>
                    <Col
                        lg={5}
                        lgOffset={0}
                        md={10}
                        mdOffset={1}
                        sm={10}
                        smOffset={1}
                        xs={10}
                        xsOffset={1}
                        className="blog-download-form-component"
                    >
                        <BlogDetailDownloadForm
                            whitePaperLink={this.props.whitePaper}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

BlogDetailDownload.defaultProps = {
    formKey: "resourceForm",
    subtitle: "",
    logoImgPath: "",
    hubspot_cta: "",
}

BlogDetailDownload.propTypes = {
    formKey: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    hubspot_cta: PropTypes.string,
    featuredImage: PropTypes.string,
    whitePaper: PropTypes.string,
    logoImgPath: PropTypes.string,
    fetchBlogDetail: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    mountForm: PropTypes.func.isRequired,
    unmountForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, actionCreators)(BlogDetailDownload)
