import styled, { css } from "styled-components"
import { QuorumTheme } from "app/static/frontend/design-constants"

export const Login = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`

export const BannerSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;
`

export const IconContainer = styled.div`
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    border: 1px solid ${QuorumTheme.colors.violet[1]};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Footer = styled.div`
    gap: 26px;
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Text = styled.div`
    color: ${QuorumTheme.colors.gray[9]};

    ${({ bigText }) =>
        bigText
            ? css`
                  font-size: 1.5rem;
                  line-height: 34px;
                  font-weight: 500;
              `
            : css`
                  font-size: 1rem;
                  line-height: 22px;
              `}

    ${({ helper }) =>
        helper &&
        css`
            color: ${QuorumTheme.colors.gray[7]};
        `}

    a {
        color: ${QuorumTheme.colors.violet[7]};
    }
`

export const Link = styled.div`
    align-self: normal;
    a {
        font-size: 0.875rem;
        :hover {
            cursor: pointer;
        }
    }
`

export const SmallForgotPasswordLink = styled(Link)`
    display: flex;
`

export const Container = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 1.5rem;
    width: 100%;

    // app/static/frontend/design-constants.ts > mobileBreakpoint
    @media (min-width: 36rem) {
        width: 22.5rem;
        max-width: 22.5rem;
        padding: 0;
    }
`

export const LoginBox = styled.div`
    padding: 2rem;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    background: white;
    box-shadow: 0px 8px 24px -8px rgba(47, 25, 128, 0.04);
`

export const Heading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`

export const LoginBanner = styled.div`
    color: ${QuorumTheme.colors.gray[9]};
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
`

export const BannerMessage = styled.span`
    color: ${QuorumTheme.colors.gray[7]};
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
`
