import React from "react"
import { Row, Col } from "react-bootstrap"
import SegueLink from "middleware/SegueLink"
import * as Logos from "quorum/static/frontend/marketing-website/constants/logos"
import { onEuProductPage } from "quorum/static/frontend/marketing-website/constants/functions"

import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues"
import FOOTER_VALUES from "quorum/static/frontend/marketing-website/constants/FooterValues"
import EmailRequest from "quorum/static/frontend/marketing-website/components/EmailRequest"

import "quorum/static/frontend/marketing-website/stylus/Footer.styl"

const SubFooter = props => (
    <Row className="sub-footer section-holder">
        <Col
            lg={2}
            lgOffset={1}
            md={2}
            mdOffset={1}
            sm={12}
            xs={12}
            className="sub-footer-col sub-footer-image"
        >
            <div
                className="sub-footer-logo-container"
                dangerouslySetInnerHTML={{ __html: Logos.whiteLogoSvg }}
                style={{ fill: "white" }}
            />
            <div
                className="sub-footer-text sub-footer-copyright"
            >
                © {MARKETING_SITE_VALUES.currentYear} Quorum Analytics Inc.
            </div>
        </Col>
        {
            FOOTER_VALUES.subFooter.columns.map(column =>
                <Col
                    lg={2}
                    md={2}
                    sm={6}
                    xs={6}
                    className="sub-footer-text sub-footer-col"
                >
                    <div
                        className="sub-footer-column-header"
                    >
                        {
                            column.headerUrl
                                ? <SegueLink
                                    to={column.headerUrl}
                                >
                                    {column.header}
                                </SegueLink>
                                : column.header
                        }
                    </div>
                    <div
                        className="sub-footer-column-items"
                    >
                        {
                            column.items.map(item =>
                                <div
                                    className="sub-footer-column-item"
                                >
                                    {
                                        item.external
                                            ? <a
                                                href={item.url}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                            >
                                                {item.title}
                                            </a>
                                            : <SegueLink
                                                to={item.url}
                                            >
                                                {item.title}
                                            </SegueLink>
                                    }
                                </div>
                            )
                        }
                    </div>
                </Col>
            )
        }
        <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="sub-footer-text sub-footer-disclaimer"
        >
            Quorum Analytics is not affiliated with, licensed, endorsed, or sponsored by Leidos Innovations Technology or its affiliates.
        </Col>
    </Row>
)

const Footer = props => {
    const isOnEuProductPage = onEuProductPage(props.location.pathname)

    return (
        <div className="footer-wrapper">
            <Row>
                <hr />
                <Col
                    lg={10}
                    lgOffset={1}
                    md={10}
                    mdOffset={1}
                    sm={10}
                    smOffset={1}
                    xs={10}
                    xsOffset={1}
                >
                    <Row className="footer">
                        <Col
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <h3 className="condensed-header">
                                About Us
                            </h3>
                            <p className="footer-text">
                                Quorum is software built for public affairs, providing one place for stakeholder engagement, legislative tracking, and grassroots advocacy.
                            </p>
                        </Col>
                        <Col
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <h3 className="condensed-header">
                                Join Our Email List
                            </h3>
                            <p className="footer-text">
                                { MARKETING_SITE_VALUES.newsletterText }
                            </p>
                            <div
                                className="footer-email-request"
                            >
                                <EmailRequest
                                    key={`footer-${props.location.pathname}`}
                                    label="Footer"
                                    shortPlaceholder
                                    location={props.location}
                                />
                            </div>
                        </Col>
                        <Col
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <h3 className="condensed-header">
                                Get in Touch
                            </h3>
                            <div className="textwidget">
                                <ul className="list-unstyled">
                                    <li>
                                        {
                                            isOnEuProductPage
                                                ? <div>
                                                    <i className="fa fa-fw fa-map-marker" />
                                                    Square de Meeûs 37
                                                    <div>4th floor</div>
                                                    <div>1000 Brussels</div>
                                                    <div>Belgium</div>
                                                </div>
                                                : <div>
                                                    <i className="fa fa-fw fa-map-marker" />
                                                    1111 19th Street NW, Suite 801
                                                    <div>Washington, DC 20036</div>
                                                </div>
                                        }
                                    </li>
                                    {
                                        !isOnEuProductPage &&
                                        <li>
                                            <i className="fa fa-fw fa-phone-square" />
                                            (202) 780-7921
                                        </li>
                                    }
                                    <li>
                                        <i className="fa fa-fw fa-envelope" />
                                        <a
                                            href={`mailto:info@${isOnEuProductPage ? "quorumeu.com" : "quorum.us"}`}
                                            style={{ color: "#303e60" }}
                                            target="_top"
                                        >
                                            { isOnEuProductPage ? "info@quorumeu.com" : "info@quorum.us" }
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/QuorumAnalytics/"
                                            className="social-icon"
                                            style={{ color: "#303e60" }}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <i className="fa fa-2x fa-facebook-square" />
                                        </a>
                                        <a
                                            href="https://twitter.com/QuorumAnalytics"
                                            className="social-icon"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <i className="fa fa-2x fa-twitter-square" />
                                        </a>
                                        <a
                                            href="https://www.linkedin.com/company/9265725"
                                            className="social-icon"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <i className="fa fa-2x fa-linkedin-square" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SubFooter />
        </div>
    )
}

export default Footer
