import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { segue } from "middleware/segueMiddleware"
import { sendUserActionPost } from "shared/user-actions/user-actions-action-creators"

/**
 *
 * @param {object} options
 * @param {string} options.to
 * @param {number} [options.datatype]
 * @param {function} [options.optionalPreSegueFunction]
 * @param {object} [options.style]
 * @param {string} [options.className]
 * @param {any} options.children
 * @returns
 */
const SegueLink = ({ to, optionalPreSegueFunction = null, dataType, ...props }) => {
    const handleClick = (e) => {
        if (optionalPreSegueFunction) {
            optionalPreSegueFunction(e)
        }

        const relCanonical = document.querySelector("head > link[rel=canonical]")
        if (to && relCanonical) {
            // If they are on the homepage, set canonical link to base url. Else, set canonical to absolute url
            if (to === "/homepage/") {
                relCanonical.href = `https://www.quorum.us/`
            } else {
                relCanonical.href = `https://www.quorum.us${to}`
            }
        }
        if (dataType === DjangIO.app.models.QuorumDataType.new_download_template.value) {
            sendUserActionPost({
                userActionType: DjangIO.app.useractions.types.UserActionType.open_existing_download,
            })
        }
    }

    const { pathname, state } = segue(to)
    // In React Router v6, <Link /> needs a `to` prop
    return to ? (
        <Link to={pathname} state={state} onClick={handleClick} {...props} />
    ) : // Else, check for an onClick handler and render as a span (Not sure if this happens anwywhere, but just in case)
    props.onClick ? (
        <span {...props} />
    ) : (
        // otherwise just render the children with no wrapper. This happens in Sheets where every Cell is wrapped in a SegueLink.
        props.children
    )
}

SegueLink.propTypes = {
    dataType: PropTypes.number,
    to: PropTypes.string,
    // provide the option to call a function before segueing somewhere
    // this function takes no arguments - you must bind the arguments beforehand
    optionalPreSegueFunction: PropTypes.func,
}

export default SegueLink
