import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"

import PillWrapper from "quorum/static/frontend/marketing-website/components/PillWrapper"
import "quorum/static/frontend/marketing-website/stylus/AddOns.styl"

const AddOns = ({ description, addOns }) => (
    <Col
        lg={12}
        className="addons-main"
    >
        <Col
            lg={8}
            lgOffset={2}
            className="addons-title section-main-header"
        >
            Add-Ons
        </Col>
        <Col
            lg={8}
            lgOffset={2}
            className="addons-description section-main-header"
        >
            {description}
        </Col>
        <PillWrapper
            pills={addOns}
        />
    </Col>
)

AddOns.propTypes = {
    description: PropTypes.string.isRequired,
    addOns: PropTypes.array.isRequired,
}

export default AddOns
