import React from "react"
import PropTypes from "prop-types"
import { Col, FormGroup, InputGroup, FormControl } from "react-bootstrap"

const SingleLineInput = (props) => {
    const formControlInput = () => (
        <div>
            <FormControl
                type="text"
                value={props.getValue(props.formValues)}
                placeholder={props.placeHolderText}
                onChange={props.handleChange}
            />
            <FormControl.Feedback />
        </div>
    )

    return (
        <Col
            lg={props.explicitColLg || 6}
            md={props.explicitColMd || 6}
            sm={props.explicitColSm || 12}
            xs={props.explicitColXs || 12}
        >
            <FormGroup validationState={props.getValidationState(props.getValue(props.formValues))}>
                {props.fontAwesomeIcon ? (
                    <InputGroup>
                        {props.fontAwesomeIcon && (
                            <InputGroup.Text>
                                <i className={`fa fa-fw ${props.fontAwesomeIcon}`} />
                            </InputGroup.Text>
                        )}
                        {formControlInput()}
                    </InputGroup>
                ) : (
                    formControlInput()
                )}
            </FormGroup>
        </Col>
    )
}

SingleLineInput.defaultProps = {
    explicitColLg: 0,
    explicitColMd: 0,
    explicitColSm: 0,
    explicitColXs: 0,
}

SingleLineInput.propTypes = {
    // Props from FormInput
    getValidationState: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    explicitColLg: PropTypes.number,
    explicitColMd: PropTypes.number,
    explicitColSm: PropTypes.number,
    explicitColXs: PropTypes.number,

    // Props from FormInput definition
    fontAwesomeIcon: PropTypes.string.isRequired,
    placeHolderText: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
}

export default SingleLineInput
