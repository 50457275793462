import React from "react"
import PropTypes from "prop-types"
// redux things
import { connect } from "react-redux"
// Components
import { Row, Col, Button, Modal } from "react-bootstrap"

import VisibleSection from "quorum/static/frontend/marketing-website/components/VisibleSection"
import OldDemoRequestForm from "quorum/static/frontend/marketing-website/components/OldDemoRequestForm"
import SchedulingForm from "quorum/static/frontend/marketing-website/components/SchedulingForm"
import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"
import * as desktopSelectors from "quorum/static/frontend/marketing-website/selectors"

import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues.js"

import "quorum/static/frontend/marketing-website/stylus/RequestDemo.styl"

const mapStateToProps = (state, props) => ({
    formValues: selectors.selectRequestDemoFormValues(state, props),
    validationDict: selectors.selectRequestDemoValidation(state, props),
    requestDemoModalShown: selectors.requestDemoModalShown(state, props),
    eventLabel: selectors.requestDemoEventLabel(state, props),
    isUsingInternetExplorer10: desktopSelectors.selectIsUsingInternetExplorer10(state, props),
})

export const RequestDemo = (props) => {
    return (
        <VisibleSection className="product-show-section-wrapper request-demo-wrapper experiment">
            <Row className="section-holder section-blue">
                <Col mdOffset={2} md={8} sm={8} smOffset={2} xs={12} xsOffset={0} className="section">
                    <div className="request-demo-header">
                        Public affairs professionals should have modern software built for them. Request a demo today to
                        learn why.
                    </div>
                    {props.isUsingInternetExplorer10 ? (
                        <OldDemoRequestForm
                            formValues={props.formValues}
                            validationDict={props.validationDict}
                            submitForm={props.submitForm}
                            formUrl={props.formUrl}
                            formKey={props.formKey}
                            updateForm={props.updateForm}
                            className="old-request-form-ie10"
                            shouldShowHeaderLanguage={false}
                        />
                    ) : (
                        <div>
                            <Button
                                className="request-demo-button btn btn-large"
                                onClick={() => props.showDemoModal("Footer")}
                            >
                                Request a Demo Now
                            </Button>

                            <Modal
                                show={props.requestDemoModalShown}
                                onHide={props.hideDemoModal}
                                className="request-demo-wrapper"
                            >
                                <Modal.Body>
                                    {MARKETING_SITE_VALUES.oldRequestFormUrls.includes(window.location.pathname) ? (
                                        <OldDemoRequestForm
                                            formValues={props.formValues}
                                            validationDict={props.validationDict}
                                            submitForm={props.submitForm}
                                            formUrl={props.formUrl}
                                            formKey={props.formKey}
                                            updateForm={props.updateForm}
                                        />
                                    ) : (
                                        <SchedulingForm
                                            email={props.formValues.get("email")}
                                            eventLabel={props.eventLabel}
                                            hideDemoModal={props.hideDemoModal}
                                            serializeQuery={props.serializeQuery}
                                        />
                                    )}
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                </Col>
            </Row>
        </VisibleSection>
    )
}

RequestDemo.defaultProps = {
    formKey: "requestDemoForm",
    formUrl: "/submit_request_demo_form/",
    eventLabel: "Bottom",
}

RequestDemo.propTypes = {
    clearForm: PropTypes.func.isRequired,
    formKey: PropTypes.string,
    formUrl: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    eventLabel: PropTypes.string,
    hideDemoModal: PropTypes.bool.isRequired,
    isUsingInternetExplorer10: PropTypes.bool.isRequired,
    requestDemoModalShown: PropTypes.bool.isRequired,
    showDemoModal: PropTypes.func.isRequired,
    hideDemoModal: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    validationDict: PropTypes.object.isRequired,
    serializeQuery: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, actionCreators)(RequestDemo)
