import Immutable from "immutable"
import { LOCATION_CHANGE } from "app/static/frontend/global-action-types"

const routerInitialState = Immutable.fromJS({ locationBeforeTransitions: null })

export const getInitialState = kwargs => routerInitialState.merge(kwargs)

// we have to override the default implementation of react-router-redux's
// routing reducer to allowus to use immutable in the state.
const immutableRoutingReducer = (state = routerInitialState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return state
                .set("locationBeforeTransitions", action.payload)
                .set("previousRoute", `${action.previousPathname}${action.previousQuery}`)
    }
    return state
}

export default immutableRoutingReducer
