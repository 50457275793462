import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
    Row,
    Col,
} from "react-bootstrap"
import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import { Helmet } from "react-helmet"

import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"

import VisibleSection from "quorum/static/frontend/marketing-website/components/VisibleSection"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"

import ABOUT_US_VALUES from "quorum/static/frontend/marketing-website/constants/aboutUsValues"

import "quorum/static/frontend/marketing-website/stylus/AboutUsPage.styl"

const mapStateToProps = (state, props) => ({
    posts: selectors.makeBlogSliceSelector("posts")(state, props),
})

export class AboutUsPage extends React.Component {
    componentDidMount() {
        // Display only the most recent, featured case studies
        this.props.fetchBlogList(`?resources=true&featured=true&blog_post_type=5&limit=${this.props.numPosts}`)
    }

    renderBiographyImage = (bio, textAlignedLeft = false, mobileScreen = false) => (
        <Col
            lg={4}
            lgHidden={mobileScreen}
            md={4}
            mdHidden={mobileScreen}
            sm={12}
            smHidden={!mobileScreen}
            xs={12}
            xsHidden={!mobileScreen}
            className={classNames(
                "about-us-biography-section",
                "about-us-picture-section",
                {
                    "image-aligned-right": textAlignedLeft,
                    "picture-section-mobile": mobileScreen,
                }
            )}
        >
            <img
                alt={bio.name}
                src={bio.imgSrc}
                className="about-us-bio-image"
            />
        </Col>
    )

    render() {
        return (
            <div className="about-us-main">
                <Helmet>
                    <title>{`${ABOUT_US_VALUES.metaTitle} | Quorum`}</title>
                    <meta
                        name="description"
                        content={ABOUT_US_VALUES.metaDescription}
                    />
                    <meta
                        property="og:title"
                        content={ABOUT_US_VALUES.metaTitle}
                    />
                    <meta
                        property="og:description"
                        content={ABOUT_US_VALUES.metaDescription}
                    />
                    <meta
                        name="twitter:title"
                        content={ABOUT_US_VALUES.metaTitle}
                    />
                    <meta
                        name="twitter:description"
                        content={ABOUT_US_VALUES.metaDescription}
                    />
                </Helmet>
                <HeroImage
                    imgPath={ABOUT_US_VALUES.imgSrc}
                    title={ABOUT_US_VALUES.title}
                    subtitle={ABOUT_US_VALUES.subtitle}
                    backgroundPositionY="30%"
                    className="about-us-hero"
                />
                {
                    ABOUT_US_VALUES.sections.map(section =>
                        <ProductShow
                            {...section}
                            key={section.id}
                            posts={this.props.posts}
                            showDemoModal={this.props.showDemoModal}
                        />
                    )
                }
                <VisibleSection>
                    <h2
                        className="about-us-biography-header section-main-header"
                    >
                        Meet Our Co-Founders
                    </h2>
                    <Col
                        lg={10}
                        lgOffset={1}
                        md={10}
                        mdOffset={1}
                        sm={10}
                        smOffset={1}
                        xs={10}
                        xsOffset={1}
                        className="about-us-biography-main"
                    >
                        {
                            ABOUT_US_VALUES.biographies.map(bio =>
                                <Row
                                    className="about-us-biography-row"
                                >
                                    {
                                        !bio.textAlignedLeft &&
                                        this.renderBiographyImage(bio, bio.textAlignedLeft)
                                    }
                                    {
                                        this.renderBiographyImage(bio, false, true)
                                    }
                                    <Col
                                        lg={8}
                                        md={8}
                                        sm={12}
                                        xs={12}
                                        className={classNames(
                                            "about-us-biography-section",
                                            "about-us-text-section",
                                            { "text-aligned-right": !bio.textAlignedLeft }
                                        )}
                                    >
                                        <h3 className="section-main-header">{bio.name}</h3>
                                        <p
                                            className="section-text-main"
                                            dangerouslySetInnerHTML={{ __html: bio.text}}
                                        />
                                        <p
                                            className="section-text-main"
                                            dangerouslySetInnerHTML={{ __html: bio.text2 }}
                                        />
                                    </Col>
                                    {
                                        bio.textAlignedLeft &&
                                        this.renderBiographyImage(bio, bio.textAlignedLeft)
                                    }
                                </Row>
                            )
                        }
                    </Col>
                </VisibleSection>
            </div>
        )
    }
}

AboutUsPage.defaultProps = {
    numPosts: 2,
}

AboutUsPage.propTypes = {
    fetchBlogList: PropTypes.func.isRequired,
    numPosts: PropTypes.number,
    posts: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, actionCreators)(AboutUsPage)
