import Immutable from "immutable"
import actionTypes from "shared/marketing-website/action-types"

import BlogType from "shared/marketing-website/constants/blogType"
import LoginType from "shared/marketing-website/constants/loginType"

export const initialState = Immutable.fromJS({
    blog: {
        blogListLoading: false,
        blogDetailLoading: false,
        posts: [],
        individualPost: {},
        error: undefined,
        nextQuery: "",
    },
    requestDemoForm: {
        formValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            jobtitle: "",
            tell_us_what_you_re_looking_for: "",
        },
        validationDict: {},
        submitted: false,
        modalShown: false,
        mounted: false,
        eventLabel: "",
    },
    blogDetailForm: {
        formValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            primary_product_persona: "",
        },
        validationDict: {},
        submitted: false,
        mounted: false,
        modalShown: false,
        eventLabel: "",
    },
    resourceForm: {
        formValues: {
            firstName: "",
            lastName: "",
            email: "",
            blogs: [
                BlogType.data_driven_politics.value,
                BlogType.productivity.value,
                BlogType.behind_the_desk.value,
                "Quorum Updates",
            ],
        },
        validationDict: {
            blogs: true,
        },
        submitted: false,
        mounted: false,
    },
    requestBlog: {
        formValues: {
            email: "",
            // List DDI, Productivity, and Behind the Desk blog types as default. Quorum Updates is not a blog type in the model
            blogs: [
                BlogType.data_driven_politics.value,
                BlogType.productivity.value,
                BlogType.behind_the_desk.value,
                "Quorum Updates",
            ],
        },
        validationDict: {
            blogs: true,
        },
        submitted: false,
        mounted: false,
    },
    resourceSearch: {
        formValues: {
            advanced_search: "",
            resources: "true",
            blog_post_type: undefined,
        },
    },
    loginPage: {
        formValues: {
            username: "",
            password: "",
            newPassword: "",
            twoFactorPassword: "",
            passwordStrength: 0,
        },
        currentStep: LoginType.login.value,
        userCode: "",
        loading: false,
        // When receiving an error, set this flag to true in order to animate a shake to the form.
        error: false,
        startAnimation: false,
        endAnimation: false,
        reverseAnimation: false,
        nextUrl: undefined,
        isLoggedIn: false,
        hasReceivedAuthentication: false,
        hasInitializedContext: false,
        isDoingMobileSSO: false,
        ssoUrl: "",
        fetchingAuthentication: false,
        errorMessage: "",
    },
    careers: {},
})

export const getInitialState = (kwargs = {}) => initialState.merge(kwargs)

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BLOG_LIST_START:
            return state.setIn(["blog", "posts"], Immutable.fromJS([])).setIn(["blog", "blogListLoading"], true)

        case actionTypes.GET_BLOG_LIST_SUCCESS:
            return state
                .setIn(["blog", "posts"], action.posts)
                .setIn(["blog", "nextQuery"], action.meta.next)
                .setIn(["blog", "blogListLoading"], false)

        case actionTypes.GET_NEXT_BLOGS_START:
            return state.setIn(["blog", "blogListLoading"], true)

        case actionTypes.GET_NEXT_BLOGS_SUCCESS:
            return state
                .setIn(["blog", "posts"], [...state.getIn(["blog", "posts"]), ...action.posts])
                .setIn(["blog", "nextQuery"], action.meta.next)
                .setIn(["blog", "blogListLoading"], false)

        case actionTypes.GET_BLOG_DETAIL_START:
            return state
                .setIn(["blog", "individualPost"], Immutable.fromJS({}))
                .setIn(["blog", "blogDetailLoading"], true)

        case actionTypes.GET_BLOG_DETAIL_SUCCESS:
            return state.setIn(["blog", "individualPost"], action.post).setIn(["blog", "blogDetailLoading"], false)

        case actionTypes.CLEAR_BLOG_DETAIL:
            return state
                .setIn(["blog", "individualPost"], Immutable.fromJS({}))
                .setIn(["blog", "blogDetailLoading"], false)

        case actionTypes.UPDATE_FORM_VALUES:
            return state.mergeIn([action.formKey, "formValues"], action.updateDict)

        case actionTypes.UPDATE_FORM_VALIDATION:
            return state.mergeIn([action.formKey, "validationDict"], action.validationDict)

        case actionTypes.SUBMIT_FORM_SUCCESS:
            return state.mergeIn([action.formKey, "submitted"], true)

        case actionTypes.SUBMIT_FORM_RESET:
            return state.mergeIn([action.formKey, "submitted"], false)

        case actionTypes.MOUNT_FORM:
            return state.mergeIn([action.formKey, "mounted"], true)

        case actionTypes.UNMOUNT_FORM:
            return state.mergeIn([action.formKey, "mounted"], false)

        case actionTypes.GET_NEXT_STEP_START:
            return state
                .setIn(["loginPage", "loading"], true)
                .setIn(["loginPage", "error"], false)
                .setIn(["loginPage", "reverseAnimation"], action.reverseAnimation || false)
                .setIn(["loginPage", "errorMessage"], "")

        case actionTypes.GET_NEXT_STEP_SUCCESS:
            return state
                .setIn(["loginPage", "currentStep"], action.currentStep)
                .setIn(["loginPage", "loading"], false)
                .setIn(["loginPage", "error"], false)

        case actionTypes.GET_NEXT_STEP_FAIL:
            return state
                .setIn(["loginPage", "currentStep"], action.currentStep)
                .setIn(["loginPage", "loading"], false)
                .setIn(["loginPage", "error"], true)

        case actionTypes.UPDATE_USER_CODE:
            return state.setIn(["loginPage", "userCode"], action.userCode)

        case actionTypes.START_ANIMATION:
            return state.setIn(["loginPage", "startAnimation"], true).setIn(["loginPage", "endAnimation"], false)

        case actionTypes.END_ANIMATION:
            return state.setIn(["loginPage", "startAnimation"], false).setIn(["loginPage", "endAnimation"], true)

        case actionTypes.CLEAR_FORM:
            return state.set(action.formKey, initialState.get(action.formKey))

        case actionTypes.CLEAR_FORM_ERROR:
            return state.setIn([action.formKey, "error"], false)

        case actionTypes.CLEAR_FORM_FIELD:
            return state.setIn(["loginPage", "formValues", action.field], "")

        case actionTypes.SET_LOGGED_IN:
            return state.setIn(["loginPage", "isLoggedIn"], action.value)

        case actionTypes.FETCH_AUTHENTICATION_START:
            return state.setIn(["loginPage", "isFetchingAuthentication"], true)

        case actionTypes.FETCH_AUTHENTICATION_SUCCESS:
            return state
                .setIn(["loginPage", "hasReceivedAuthentication"], action.value)
                .setIn(["loginPage", "isFetchingAuthentication"], false)

        case actionTypes.FETCH_CONTEXT_SUCCESS:
            return state.setIn(["loginPage", "hasInitializedContext"], action.value)

        case actionTypes.REDIRECT_TO:
            return state.setIn(["loginPage", "nextUrl"], action.nextUrl)

        case actionTypes.MOBILE_SSO_START:
            return state.setIn(["loginPage", "isDoingMobileSSO"], true).setIn(["loginPage", "ssoUrl"], action.url)

        case actionTypes.MOBILE_SSO_SUCCESS:
            return state.setIn(["loginPage", "isDoingMobileSSO"], false).setIn(["loginPage", "ssoUrl"], "")

        case actionTypes.GET_AVAILABLE_POSITIONS:
            const careers = action.jobs.reduce((acc, job) => {
                const department = job.departments[0].name

                if (acc[department]) {
                    acc[department].push(job)
                } else {
                    acc[department] = [job]
                }

                return acc
            }, {})
            return state.set("careers", careers)

        case actionTypes.SHOW_REQUEST_DEMO_MODAL:
            return state
                .setIn(["requestDemoForm", "modalShown"], true)
                .setIn(["requestDemoForm", "eventLabel"], action.eventLabel)

        case actionTypes.HIDE_REQUEST_DEMO_MODAL:
            return state.setIn(["requestDemoForm", "modalShown"], false)

        case actionTypes.SET_ERROR_MESSAGE:
            return state.setIn(["loginPage", "errorMessage"], action.errorMessage)

        default:
            return state
    }
}

export default reducer
