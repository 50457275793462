import React from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

/**
 * HOC that adds the props.params and props.location.query props to a component. Used for backwards compatibility
 * when this was automatically done by React Router v3.
 *
 * If you are creating a new page, or significantly refactoring an existing page, you should _not_ use this HOC,
 * and instead use the hooks provided by React Router directly.
 *
 * @example <Route path="/:id" component={withRouter(MyComponent)} />
 */
const withRouter = (Component) => {
    const ComponentWithRouterProp = (props) => {
        const location = useLocation()
        const query = Object.fromEntries(new URLSearchParams(location.search))
        const navigate = useNavigate()
        const params = useParams()

        return (
            <Component
                {...props}
                navigate={navigate}
                params={params}
                location={{
                    ...location,
                    query,
                }}
            />
        )
    }

    return ComponentWithRouterProp
}

export default withRouter
