import React from "react"
import PropTypes from "prop-types"
import {
    Button,
    Col,
    Row,
} from "react-bootstrap"
import swal from 'sweetalert'

import FormInput from "quorum/static/frontend/marketing-website/components/FormInput"
import SingleLineInput from "quorum/static/frontend/marketing-website/components/SingleLineInput"
import MultiLineInput from "quorum/static/frontend/marketing-website/components/MultiLineInput"

import { alwaysValid, emailValidation, phoneNumberValidation } from "shared/imports/validationFunctions"
import { checkSubmission } from "quorum/static/frontend/marketing-website/constants/functions"
import { readCookieByKey } from "shared/imports/sharedHelperFunctions"

import "quorum/static/frontend/marketing-website/stylus/OldDemoRequestForm.styl"

const OldDemoRequestForm = (props) => {
    const validateAndSubmit = () => {
        const [allDefined, allValid] = checkSubmission(props.formValues, props.validationDict)

        if (!allDefined) {
            swal({
                title: "Please fill out all the fields.",
                icon: "warning",
            })
        } else if (!allValid) {
            // swall invalid forms
            swal({
                title: "Please make sure all fields have valid input.",
                icon: "warning",
            })
        } else {
            // in the case that we can submit
            const data = {
                firstname: props.formValues.get("firstName"),
                lastname: props.formValues.get("lastName"),
                email: props.formValues.get("email"),
                phone: props.formValues.get("phone"),
                company: props.formValues.get("company"),
                jobtitle: props.formValues.get("jobtitle"),
                salutation: props.formValues.get("tell_us_what_you_re_looking_for"),
                hs_context: JSON.stringify({
                    hutk: readCookieByKey('hubspotutk'),
                    pageUrl: window.location.href,
                    pageName: document.title
                })
            }

            // Google Analytics event tracking
            gtag('event', window.location.pathname, {
                event_category: 'Demo',
                event_label: 'Bottom',
            })

            // Bing conversion tracking
            window.uetq.push({ 'ec': 'Demo', 'ea': window.location.pathname, 'el': 'Bottom', 'ev': 50 })

            props.submitForm(props.formUrl, data, props.formKey)

            swal({
                title: "Thank you!",
                text: "We'll reach out to you shortly.",
                icon: "success",
            })
        }
    }

    return (
        <Row
            className={`old-demo-request-form-main section-holder ${props.className}`}
        >
            <Col
                mdOffset={1}
                md={10}
                sm={10}
                smOffset={1}
                xs={10}
                xsOffset={1}
                className="request-demo-column"
            >
                {
                    props.shouldShowHeaderLanguage &&
                    <div className="request-demo-text">
                        <h3 className="request-demo-main-header">
                            Request Demo
                        </h3>
                        <h4 className="request-demo-text-main">
                            Public affairs professionals should have modern software built for them. Request a demo today to learn why.
                        </h4>
                    </div>
                }
                <Row>
                    <Col
                        md={10}
                        mdOffset={1}
                        sm={10}
                        smOffset={1}
                        className="request-demo-form-column"
                    >
                        <form
                            className="request-demo-form"
                        >
                            <FormInput
                                placeHolderText="First Name"
                                fieldKey="firstName"
                                fontAwesomeIcon="fa-user"
                                updateForm={props.updateForm}
                                formValues={props.formValues}
                                validationFunction={alwaysValid}
                                formKey={props.formKey}
                                presentationComponent={SingleLineInput}
                            />
                            <FormInput
                                placeHolderText="Last Name"
                                fieldKey="lastName"
                                fontAwesomeIcon="fa-user"
                                updateForm={props.updateForm}
                                formValues={props.formValues}
                                validationFunction={alwaysValid}
                                formKey={props.formKey}
                                presentationComponent={SingleLineInput}
                            />
                            <FormInput
                                placeHolderText="Work Email"
                                fieldKey="email"
                                fontAwesomeIcon="fa-envelope"
                                updateForm={props.updateForm}
                                formValues={props.formValues}
                                validationFunction={emailValidation}
                                formKey={props.formKey}
                                presentationComponent={SingleLineInput}
                            />
                            <FormInput
                                placeHolderText="Phone Number"
                                fieldKey="phone"
                                fontAwesomeIcon="fa-phone"
                                updateForm={props.updateForm}
                                formValues={props.formValues}
                                validationFunction={phoneNumberValidation}
                                formKey={props.formKey}
                                presentationComponent={SingleLineInput}
                            />
                            <FormInput
                                placeHolderText="Company"
                                fieldKey="company"
                                fontAwesomeIcon="fa-users"
                                updateForm={props.updateForm}
                                formValues={props.formValues}
                                validationFunction={alwaysValid}
                                formKey={props.formKey}
                                presentationComponent={SingleLineInput}
                            />
                            <FormInput
                                placeHolderText="Job Title"
                                fieldKey="jobtitle"
                                fontAwesomeIcon="fa-quote-right"
                                updateForm={props.updateForm}
                                formValues={props.formValues}
                                validationFunction={alwaysValid}
                                formKey={props.formKey}
                                presentationComponent={SingleLineInput}
                            />
                            <FormInput
                                placeHolderText="Tell us what you're looking for."
                                fieldKey="tell_us_what_you_re_looking_for"
                                updateForm={props.updateForm}
                                formValues={props.formValues}
                                validationFunction={alwaysValid}
                                formKey={props.formKey}
                                presentationComponent={MultiLineInput}
                            />
                        </form>
                        <Button
                            className="request-demo-submit btn-lg btn-block"
                            onClick={validateAndSubmit}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

OldDemoRequestForm.defaultProps = {
    className: '',
    shouldShowHeaderLanguage: true,
}

OldDemoRequestForm.propTypes = {
    className: PropTypes.string,
    formKey: PropTypes.string.isRequired,
    formUrl: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    shouldShowHeaderLanguage: PropTypes.bool,
    submitForm: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    validationDict: PropTypes.object.isRequired,
}

export default OldDemoRequestForm
