import React from "react"
import PropTypes from "prop-types"

import { Col } from "react-bootstrap"
import {
    downloadPDFCrossDomain,
    makeShortCode,
} from "imports/desktopHelperFunctions"

import SegueLink from "middleware/SegueLink"

import "app/static/stylus/PictureThumbnail.styl"

const PictureThumbnail = ({
    url,
    imgPath,
    logoImgPath,
    header,
    title,
    titleFontSize,
    shortCode,
    segueToUrl,
    newTabToUrl,
    downloadFile,
    downloadUrl,
    length,
    lgWidth,
    mdWidth,
    smWidth,
    smHidden,
    xsHidden,
}) => {
    const DummyLink = dummyProps => (
        segueToUrl
            // If segueToUrl is true, render SegueLink
            ? <SegueLink to={url}>{dummyProps.children}</SegueLink>
            : (newTabToUrl || !downloadUrl)
                // Otherwise, if newTabToUrl is true (or there is no download url), render a tag with target="_blank"
                ? <a href={url} target="_blank">{dummyProps.children}</a>
                : downloadFile
                    // If all else is false, and downloadFile is true, download the file at downloadUrl
                    ? <a onClick={() => downloadPDFCrossDomain(downloadUrl, shortCode || makeShortCode(title))}>{dummyProps.children}</a>
                    : <a>{dummyProps.children}</a>
    )

    return (
        <Col
            lg={lgWidth}
            lgOffset={0}
            md={mdWidth}
            mdOffset={0}
            sm={smWidth}
            smOffset={0}
            smHidden={smHidden}
            xs={12}
            xsOffset={0}
            xsHidden={xsHidden}
            className="picture-thumbnail-main"
        >
            <DummyLink>
                <div
                    className="picture-thumbnail-image"
                    style={{
                        backgroundImage: `url(${imgPath}`,
                    }}
                >
                    <div
                        className="picture-thumbnail-type section-text-main"
                    >
                        {header}
                    </div>
                    <h4
                        className="picture-thumbnail-title"
                        style={{
                            titleFontSize
                        }}
                    >
                        {title}
                    </h4>
                    {
                        logoImgPath &&
                        <div
                            className="picture-thumbnail-logo"
                            style={{
                                backgroundImage: `url(${logoImgPath})`
                            }}
                        />
                    }
                    {
                        length &&
                        <div className="picture-thumbnail-length">
                            {`${length} Minutes`}
                        </div>
                    }
                </div>
            </DummyLink>
        </Col>
    )
}


PictureThumbnail.defaultProps = {
    url: "",
    header: "",
    logoImgPath: "",
    shortCode: "",
    segueToUrl: true,
    newTabToUrl: false,
    downloadFile: false,
    downloadUrl: "",
    lgWidth: 3,
    mdWidth: 6,
    smWidth: 6,
    smHidden: false,
    xsHidden: false,
    length: undefined,
}

PictureThumbnail.propTypes = {
    url: PropTypes.string,
    header: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleFontSize: PropTypes.number,
    shortCode: PropTypes.string,
    imgPath: PropTypes.string.isRequired,
    logoImgPath: PropTypes.string,
    segueToUrl: PropTypes.bool,
    newTabToUrl: PropTypes.bool,
    downloadFile: PropTypes.bool,
    downloadUrl: PropTypes.string,
    length: PropTypes.number,
    lgWidth: PropTypes.number,
    mdWidth: PropTypes.number,
    smWidth: PropTypes.number,
    smHidden: PropTypes.bool,
    xsHidden: PropTypes.bool,
}

export default PictureThumbnail
