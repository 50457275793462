import React from "react"
import {
    Row,
    Col,
} from "react-bootstrap"

import VisibleSection from "quorum/static/frontend/marketing-website/components/VisibleSection"
import SegueLink from "middleware/SegueLink"
import urls from "shared/marketing-website/constants/urls"
import { generateStaticUrl } from "imports/desktopHelperFunctions"


import "quorum/static/frontend/marketing-website/stylus/AsFeaturedIn.styl"

const AsFeaturedIn = () => (
    <VisibleSection
        className="as-featured-in"
    >
        <Row className="section-holder as-featured-in-section-holder">
            <SegueLink
                to={urls.press}
            >
                <Col
                    md={10}
                    sm={10}
                    xs={10}
                    mdOffset={1}
                    smOffset={1}
                    xsOffset={1}
                    className="as-featured-in-column"
                >
                    <h3 className="condensed-text as-featured-in-text">
                        AS FEATURED IN
                    </h3>
                    <div className="as-featured-in-img-wrapper">
                        <img
                            className="as-featured-in-desktop-img hidden-xs hidden-sm"
                            src={generateStaticUrl("apps/marketing-website/images/media-imgs/media-bar-5.png")}
                            alt=""
                        />
                        <img
                            className="as-featured-in-mobile-img hidden-md hidden-lg"
                            src={generateStaticUrl("apps/marketing-website/images/media-imgs/media-bar-mobile-1.png")}
                            alt=""
                        />
                        <img
                            className="as-featured-in-mobile-img hidden-md hidden-lg"
                            src={generateStaticUrl("apps/marketing-website/images/media-imgs/media-bar-mobile-2.png")}
                            alt=""
                        />
                        <img
                            className="as-featured-in-mobile-img as-featured-in-mobile-img-last hidden-md hidden-lg"
                            src={generateStaticUrl("apps/marketing-website/images/media-imgs/media-bar-mobile-3.png")}
                            alt=""
                        />
                    </div>
                </Col>
            </SegueLink>
        </Row>
    </VisibleSection>
)

export default AsFeaturedIn
