import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"
import FeaturedArticle from "quorum/static/frontend/marketing-website/components/FeaturedArticle"

import { generateStaticUrl } from "imports/desktopHelperFunctions"

const STATE_PRODUCT_VALUES = {
    meta: {
        title: "State",
        description: "Centralize your team's advocacy with 50 state bill tracking and social media monitoring integrated into Quorum's public affairs software.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/states_banner.jpg"),
                title: "State",
                subtitle: "Bill and regulatory tracking, social media alerts, contact directory, and email tool.",
                backgroundPositionY: "65%",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "legislative-tracking",
                key: "legislative-tracking",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "State Legislative Tracking",
                sectionLeadHeader: "Bring Order to Your Legislative Tracking",
                sectionText: "Easily manage your workflow with neatly organized legislative dashboards customized to your issue areas, keywords, and priorities.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/states/legislative_tracking_2.png"),
                altText: "bring-order-to-tracking",
                secondaryCTA: {
                    link: "/resources/state-bill-tracking/141/",
                    label: "Make Bill Tracking Central to Your Advocacy"
                }
            },
        },
        {
            component: FeaturedArticle,
            props: {
                id: "state-legislative-tracking",
                key: "white-paper-1",
                leadTitle: "Featured Whitepaper",
                url: "/resources/state-bill-tracking/141/",
                title: "Make State Legislative Tracking a Centerpiece of Your Advocacy",
                external: false,
                imgPath: generateStaticUrl("apps/marketing-website/images/QuorumStates_IMac.jpg"),
            },
        },
        {
            component: ProductShow,
            props: {
                id: "state-regulations",
                key: "state-regulations",
                textAlignedLeft: false,
                sectionMainHeader: "State Regulations",
                sectionLeadHeader: "Monitor Regulatory Threats At The State Level",
                sectionText: "Track state regulations in one standardized and searchable database and be alerted when there’s a new update on regulations you follow. Every regulation has a standardized and comprehensive profile so you have every related piece of information in one place.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/regulations/state-regs-profile.001.png"),
                altText: "state-regulations",
                secondaryCTA: {
                    link: "/regulations/",
                    label: "Learn More"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "social-media-tracking",
                key: "social-media-tracking",
                textAlignedLeft: true,
                theme: "section-purple",

                sectionMainHeader: "Social Media Tracking",
                sectionLeadHeader: "Never Miss A Mention",
                sectionText: "With the world’s most comprehensive database of legislative information you’ll never miss a mention of your organization or issue across legislators’ tweets, Facebook posts, press releases, and emails to their constituents.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/states/social_media_tracking_2.png"),
                altText: "never-miss-mention",
                secondaryCTA: {
                    link: "/case-studies/crisis-communications-trump-tweet/147/",
                    label: "Respond to Trump's Tweets Like Toyota"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "custom-data",
                key: "custom-data",
                textAlignedLeft: false,

                sectionMainHeader: "Custom Data",
                sectionLeadHeader: "Communicate Your Organization's Footprint ",
                sectionText: "Map your facilities, members, employees, and suppliers to every state legislative district in the country. Easily pull together custom biographies, mentions of your organization, and your organization’s previous interactions with a legislator into a simple downloadable PDF report.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/states/custom_data_2.png"),
                altText: "communicate-your-footprint",
                secondaryCTA: {
                    link: "/resources/custom-data/138/",
                    label: "Leverage the Power of your Organization's Data"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "bill-text-comparison",
                key: "bill-text-comparison",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "State Bill Text Comparison",
                sectionLeadHeader: "Know When Changes Occur",
                sectionText: "Identify key changes in legislation between chambers and over time. Create keyword email alerts to stay on top of every change. ",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/states/bill_text_comparison_2.png"),
                altText: "know-when-changes-occur",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "project-management",
                key: "project-management",
                textAlignedLeft: false,
                theme: "section-blue",
                sectionMainHeader: "Project Management",
                sectionLeadHeader: "Built For Teams",
                sectionText: "Coordinate a legislative strategy as unified or as segmented as you need across all 50 states and enable your team to identify trends over time or target key areas of legislative activity.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/states/project_management_3.png"),
                altText: "built-for-teams",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "outbox",
                key: "outbox",
                textAlignedLeft: true,
                sectionMainHeader: "Integrated Email Tool",
                sectionLeadHeader: "Easily Contact State Legislators",
                sectionText: "Deliver personalized messages to state legislators using plain-text emails or advanced templates. Quickly access a full database of legislator contact information and filter by party, committees, chambers, regions, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/states/integrated_email_tool_2.png"),
                altText: "outbox-left",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "mobile-app",
                key: "mobile-app",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Mobile App",
                sectionLeadHeader: "Keep Track of Every Meeting",
                sectionText: "Log your meetings with legislators using Quorum’s mobile app to preserve institutional knowledge, easily manage compliance reports, keep your team aligned, and measure your impact.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/states/mobile_app_2.png"),
                altText: "track-every-meeting",
                secondaryCTA: {
                    link: "/resources/measuring-government-affairs/139/",
                    label: "Learn to Measure Your Impact"
                }
            },
        },

    ]
}

export default STATE_PRODUCT_VALUES
