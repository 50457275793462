import React from "react"
import PropTypes from "prop-types"
import swal from 'sweetalert'
// redux things
import { connect } from "react-redux"
// Components
import {
    Row,
    Col,
    Button,
} from "react-bootstrap"
import classNames from "classnames"
import VisibleSection from "quorum/static/frontend/marketing-website/components/VisibleSection"
import FormInput from "quorum/static/frontend/marketing-website/components/FormInput"
import SingleLineInput from "quorum/static/frontend/marketing-website/components/SingleLineInput"
import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"

import {
    alwaysValid,
    emailValidation,
    phoneNumberValidation,
} from "shared/imports/validationFunctions"
import {
    checkSubmission,
    scrollToRequestDemo,
} from "quorum/static/frontend/marketing-website/constants/functions"
import {
    readCookieByKey,
    downloadPDFCrossDomain,
} from "imports/desktopHelperFunctions"

import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues"
import BlogType from "shared/marketing-website/constants/blogType"

import "quorum/static/frontend/marketing-website/stylus/BlogDetailDownloadForm.styl"

const mapStateToProps = (state, props) => ({
    formValues: selectors.selectBlogDetailDownloadFormValues(state, props),
    validationDict: selectors.selectWhitePaperValidation(state, props),
    blogPostType: selectors.makeBlogIndividualSelector("blog_post_type")(state),
    ctaButtonText: selectors.makeBlogIndividualSelector("cta_button_text")(state),
    slug: selectors.makeBlogIndividualSelector("slug")(state),
    formHeader: selectors.makeBlogIndividualSelector("form_header")(state),
    formSubtext: selectors.makeBlogIndividualSelector("form_subtext")(state),
    gatedContentUrl: selectors.makeBlogIndividualSelector("gated_content_url")(state),
    requireCompanyField: selectors.makeBlogIndividualSelector("should_require_company_field")(state),
    requirePhoneField: selectors.makeBlogIndividualSelector("should_require_phone_field")(state),
    submitted: selectors.selectResourceFormSubmitted(state, props),
})

export const BlogDetailDownloadForm = (props) => {
    const validateAndSubmit = () => {
        const [allDefined, allValid] = checkSubmission(props.formValues, props.validationDict)

        if (!allDefined) {
            swal({
                title: "Please fill out all the fields.",
                icon: "warning",
            })
        } else if (!allValid) {
            // swall invalid forms
            swal({
                title: "Please make sure all fields have valid input.",
                icon: "warning",
            })
        } else {
            // in the case that we can submit
            const data = {
                firstname: props.formValues.get("firstName"),
                lastname: props.formValues.get("lastName"),
                email: props.formValues.get("email"),
                blogs: props.formValues.get("blogs").toArray(),
                hs_context: JSON.stringify({
                    hutk: readCookieByKey('hubspotutk'),
                    pageUrl: window.location.href,
                    pageName: document.title
                })
            }

            const company = props.formValues.get("company")
            if (company) {
                data['company'] = company
            }

            const jobtitle = props.formValues.get("jobtitle")
            if (jobtitle) {
                data['jobtitle'] = jobtitle
            }

            const phone = props.formValues.get("phone")
            if (phone) {
                data['phone'] = phone
            }

            // Google Analytics event tracking
            gtag('event', window.location.pathname, {
                event_category: 'Download Content',
                event_label: 'Main',
            })

            return props.submitForm(props.formUrl, data, props.formKey)
        }
    }

    const navigateToGatedContentUrl = () => {
        if (props.gatedContentUrl) {
            window.open(props.gatedContentUrl, '_blank')
        }
    }

    const downloadContent = () => {
        const submitted = validateAndSubmit()

        if (submitted) {
            submitted.then(() => {
                if (props.whitePaperLink) {
                    downloadPDFCrossDomain(props.whitePaperLink, props.slug).then(() => {
                        navigateToGatedContentUrl()
                    })
                } else {
                    navigateToGatedContentUrl()
                }
            })
        }
    }

    const toggleBlogs = () => {
        const newBlogs = [
            BlogType.data_driven_politics.value,
            BlogType.productivity.value,
            BlogType.behind_the_desk.value,
            "Quorum Updates",
        ]

        // If there are blogs selected, we want to clear all of the blogs and pass in an empty list
        // If there are no blogs selected, we want to select all the blogs and update the store.
        const blogs = props.formValues.get('blogs').size ? [] : newBlogs

        props.updateForm(
            props.formKey,
            { blogs }
        )
    }

    return (
        <VisibleSection className="blog-download-form-wrapper">
            <Row
                className="section-holder"
                id="blog-download-form"
            >
                <Col
                    mdOffset={1}
                    md={10}
                    sm={10}
                    smOffset={1}
                    xs={12}
                    xsOffset={0}
                    className="blog-download-form-column"
                >
                    {
                        props.submitted
                        ? <div
                            className="blog-download-form-submitted"
                        >
                            <div
                                className="section-main-header"
                            >
                                Thank You For Downloading!
                            </div>
                            <p className="section-text-main">
                                Would you like to learn more about Quorum? <a onClick={() => props.showDemoModal("Gated Content")}>Request a demo</a> and we'd love to show how great software built for you can be.
                            </p>
                        </div>
                        : <div>
                            <div className="blog-download-form-text">
                                <h3 className="blog-download-main-header">
                                    {props.formHeader || `Download ${props.blogPostType ? BlogType[props.blogPostType].singular : ""}`}
                                </h3>
                                <h4 className="blog-download-subheader">
                                    {props.formSubtext || `Fill out the form below to get the ${props.blogPostType ? BlogType[props.blogPostType].singularLowercase : ""}!`}
                                </h4>
                            </div>
                            <Row>
                                <Col
                                    md={10}
                                    mdOffset={1}
                                    sm={10}
                                    smOffset={1}
                                    className="blog-download-form-col"
                                >
                                    <form
                                        className="blog-download-form-inputs"
                                    >
                                        <FormInput
                                            placeHolderText="First Name"
                                            fieldKey="firstName"
                                            fontAwesomeIcon="fa-user"
                                            updateForm={props.updateForm}
                                            formValues={props.formValues}
                                            validationFunction={alwaysValid}
                                            formKey={props.formKey}
                                            presentationComponent={SingleLineInput}
                                        />
                                        <FormInput
                                            placeHolderText="Last Name"
                                            fieldKey="lastName"
                                            fontAwesomeIcon="fa-user"
                                            updateForm={props.updateForm}
                                            formValues={props.formValues}
                                            validationFunction={alwaysValid}
                                            formKey={props.formKey}
                                            presentationComponent={SingleLineInput}
                                        />
                                        {
                                            props.requireCompanyField &&
                                                <FormInput
                                                    placeHolderText="Company"
                                                    fieldKey="company"
                                                    fontAwesomeIcon="fa-users"
                                                    updateForm={props.updateForm}
                                                    formValues={props.formValues}
                                                    validationFunction={alwaysValid}
                                                    formKey={props.formKey}
                                                    presentationComponent={SingleLineInput}
                                                />
                                        }
                                        {
                                            props.requireCompanyField &&
                                                <FormInput
                                                    placeHolderText="Job Title"
                                                    fieldKey="jobtitle"
                                                    fontAwesomeIcon="fa-quote-right"
                                                    updateForm={props.updateForm}
                                                    formValues={props.formValues}
                                                    validationFunction={alwaysValid}
                                                    formKey={props.formKey}
                                                    presentationComponent={SingleLineInput}
                                                />
                                        }
                                        <FormInput
                                            placeHolderText="Work Email"
                                            fieldKey="email"
                                            fontAwesomeIcon="fa-envelope"
                                            updateForm={props.updateForm}
                                            formValues={props.formValues}
                                            validationFunction={emailValidation}
                                            formKey={props.formKey}
                                            presentationComponent={SingleLineInput}
                                            explicitColLg={props.requirePhoneField ? 6 : 12}
                                        />
                                        {
                                            props.requirePhoneField &&
                                            <div className="conditional-fields">
                                                <FormInput
                                                    placeHolderText="Phone Number"
                                                    fieldKey="phone"
                                                    fontAwesomeIcon="fa-phone"
                                                    updateForm={props.updateForm}
                                                    formValues={props.formValues}
                                                    validationFunction={phoneNumberValidation}
                                                    formKey={props.formKey}
                                                    presentationComponent={SingleLineInput}
                                                />
                                            </div>
                                        }
                                    </form>
                                    <div
                                        onClick={toggleBlogs}
                                        className="blog-download-signup-button"
                                    >
                                        <i
                                            className={classNames(
                                                "fa",
                                                {
                                                    "fa-check-square-o": props.formValues.get('blogs').size,
                                                    "fa-square-o": !props.formValues.get('blogs').size,
                                                }
                                            )}
                                        />
                                        { MARKETING_SITE_VALUES.newsletterText }
                                    </div>
                                    <Button
                                        className="blog-download-form-submit btn-lg btn-block"
                                        onClick={downloadContent}
                                    >
                                        {props.ctaButtonText || "Download"}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    }
                </Col>
            </Row>
        </VisibleSection>
    )
}

BlogDetailDownloadForm.defaultProps = {
    formKey: "resourceForm",
    formUrl: "/submit_resource_request_form/",
    formHeader: '',
    formSubtext: '',
    gatedContentUrl: '',
    requireCompanyField: false,
    requirePhoneField: false,
}

BlogDetailDownloadForm.propTypes = {
    formKey: PropTypes.string,
    formUrl: PropTypes.string,
    updateForm: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    validationDict: PropTypes.object.isRequired,
    submitForm: PropTypes.func.isRequired,
    whitePaperLink: PropTypes.string,
    blogs: PropTypes.object,
    ctaButtonText: PropTypes.string,
    slug: PropTypes.string,
    formHeader: PropTypes.string,
    formSubtext: PropTypes.string,
    gatedContentUrl: PropTypes.string,
    requireCompanyField: PropTypes.bool,
    requirePhoneField: PropTypes.bool,
}

export default connect(mapStateToProps, actionCreators)(BlogDetailDownloadForm)
