import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const BlogType = {
    data_driven_politics: {
        value: 1,
        // Label for BlogThumbnails and filter buttons
        label: "Data Driven Insights",
        heroImageLabel: "Data Driven Insights",
        subtitle: "A data-driven look at trending conversations in politics",
        imgPath: generateStaticUrl("apps/marketing-website/images/quorum_banner_laptops.jpg"),
        formUrl: "https://forms.hubspot.com/uploads/form/v2/2054723/54fae52f-30ba-491b-a390-ad86e0d2eda5",
        eventCategory: "DDI",
        singular: "Blog Post",
        singularLowercase: "blog post",
        // Full url of resources page with this filter selected
        url: "/resources/data-driven-insights/",
        // Second url param in '/resources/____/'
        urlPattern: "data-driven-insights",
        // Url pattern of the detail view. Usually same as urlPattern or "resources"
        detailUrlPattern: "data-driven-insights",
        metaDescription: "Quorum's Data Driven Insights blog series covers trending conversations in politics through the lens of the world's most comprehensive legislative database.",
    },
    productivity: {
        value: 2,
        label: "Congressional Activity",
        heroImageLabel: "Congressional Activity Tracker",
        subtitle: "Your weekly snapshot of activity in Congress",
        imgPath: generateStaticUrl("apps/marketing-website/images/capitol_at_dusk.jpg"),
        formUrl: "https://forms.hubspot.com/uploads/form/v2/2054723/8075b2f0-590f-43d5-8e0d-4175cc154c4b",
        eventCategory: "",
        singular: "Blog Post",
        singularLowercase: "blog post",
        url: "/resources/congressional-activity-tracker/",
        urlPattern: "congressional-activity-tracker",
        detailUrlPattern: "congressional-activity-tracker",
        metaDescription: "Quorum's Congressional Activity Tracker blog series highlights monthly and historical legislative productivity, as well as trends in dialogue on Capitol Hill.",
        specificPostUrl: "/congressional-activity-tracker/home/350/",
    },
    white_papers: {
        value: 4,
        label: "Downloads",
        heroImageLabel: "Downloads",
        subtitle: "Reports and insights for public affairs professionals",
        imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/main_banner.jpg"),
        formUrl: "",
        eventCategory: "",
        singular: "White Paper",
        singularLowercase: "white paper",
        url: "/resources/downloads/",
        urlPattern: "downloads",
        detailUrlPattern: "resources",
        metaDescription: "Quorum's white papers highlight best practices and use cases organizations can utilize to impact the issues they care most about.",
    },
    case_studies: {
        value: 5,
        label: "Case Studies",
        heroImageLabel: "Case Studies",
        subtitle: "Clients using Quorum to innovate in public affairs",
        imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/main_banner.jpg"),
        formUrl: "",
        eventCategory: "",
        singular: "Case Study",
        singularLowercase: "case study",
        url: "/resources/case-studies/",
        urlPattern: "case-studies",
        detailUrlPattern: "case-studies",
        metaDescription: "Quorum's case studies highlight organizations using best practices to impact the issues they care most about.",
    },
    behind_the_desk: {
        value: 6,
        label: "Behind the Desk",
        heroImageLabel: "Behind the Desk",
        subtitle: "Exclusive interviews with elected officials",
        imgPath: generateStaticUrl("apps/marketing-website/images/behind_the_desk.jpg"),
        formUrl: "https://forms.hubspot.com/uploads/form/v2/2054723/54fae52f-30ba-491b-a390-ad86e0d2eda5",
        eventCategory: "",
        singular: "Blog Post",
        singularLowercase: "blog post",
        url: "/resources/behind-the-desk/",
        urlPattern: "behind-the-desk",
        detailUrlPattern: "behind-the-desk",
        metaDescription: "Quorum's Behind the Desk blog series covers exclusive interviews with elected officials and their staff about the inner workings of federal, state, and local government offices.",
    },
    best_practices: {
        value: 7,
        label: "Public Affairs Best Practices",
        heroImageLabel: "Public Affairs Best Practices",
        subtitle: "Strategies from public affairs professionals",
        imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/main_banner.jpg"),
        formUrl: "",
        eventCategory: "",
        singular: "Blog Post",
        singularLowercase: "blog post",
        url: "/resources/best-practices/",
        urlPattern: "best-practices",
        detailUrlPattern: "best-practices",
        metaDescription: "Quorum's public affairs best practice blog showcases strategy from experienced public affairs professionals.",
    },
    public_affairs_dictionary: {
        value: 8,
        label: "Dictionary",
        heroImageLabel: "Public Affairs Dictionary",
        subtitle: "Key terms every public affairs professional should know",
        imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/main_banner.jpg"),
        formUrl: "",
        eventCategory: "",
        singular: "Blog Post",
        singularLowercase: "blog post",
        url: "/resources/public-affairs-dictionary/",
        urlPattern: "public-affairs-dictionary",
        detailUrlPattern: "public-affairs-dictionary",
        metaDescription: "Quorum's public affairs dictionary offer definitions for the most important words in the public affairs industry.",
    },
}

export default BlogType
