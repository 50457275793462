import React from "react"
import PropTypes from "prop-types"
import {
    Row,
    Col,
} from "react-bootstrap"

import VisibleSection from "quorum/static/frontend/marketing-website/components/VisibleSection"
import "quorum/static/frontend/marketing-website/stylus/BlueSection.styl"

const BlueSection = ({ text }) => (
    <VisibleSection className="blue-section-main">
        <Row
            className="section-holder section-blue"
        >
            <Col
                mdOffset={2}
                md={8}
                sm={8}
                smOffset={2}
                xs={12}
                xsOffset={0}
                className="section"
            >
                <div
                    className="blue-section-text"
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </Col>
        </Row>
    </VisibleSection>
)

BlueSection.defaultProps = {
    text: ""
}

BlueSection.propTypes = {
    text: PropTypes.string,
}

export default BlueSection
