import React from "react"
import Waypoint, { resolveScrollableAncestorProp } from "react-waypoint"
import classNames from "classnames"

/*
 * This is a simple component for introducing CSS transitions and animations
 * when an element becomes visible. 
 * 
 * It adds the class "visible" and wraps any children in its "className" prop.
 */

class VisibleSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }

    setVisiblity = ({ previousPosition, currentPosition, event }) => {
        if (previousPosition) {
            this.setState({ visible: true })
        }
    }

    render() {
        return (
            <div 
                className={classNames({
                    "visible-section-wrapper": true,
                    [this.props.className]: true,
                    visible: this.state.visible,
                })}
            >
                {this.props.children}
                <Waypoint onEnter={this.setVisiblity} />
                <hr />
            </div>
        )
    }
}

export default VisibleSection
