import React from "react"
import PropTypes from "prop-types"

import { generateMediaUrl } from "shared/imports/sharedHelperFunctions"

import BlogType from "shared/marketing-website/constants/blogType"
import PictureThumbnail from "components/PictureThumbnail"

import "quorum/static/frontend/marketing-website/stylus/BlogThumbnail.styl"

const BlogThumbnail = ({
    id,
    blogPostType,
    title,
    imgPath,
    shortCode,
    logoImgPath,
    lgWidth,
    mdWidth,
    smWidth,
    smHidden,
    xsHidden,
    fontSize,
}) => {
    const getUrl = () => {
        const urlShortCode = shortCode || title.split(" ").slice(0, 5).join("-")

        const urlPattern = BlogType[blogPostType].detailUrlPattern

        return `/${urlPattern}/${urlShortCode}/${id}/`
    }

    return (
        <PictureThumbnail
            url={getUrl()}
            imgPath={generateMediaUrl(imgPath)}
            header={BlogType[blogPostType].label}
            logoImgPath={generateMediaUrl(logoImgPath)}
            title={title}
            titleFontSize={fontSize}
            lgWidth={lgWidth}
            mdWidth={mdWidth}
            smWidth={smWidth}
            smHidden={smHidden}
            xsHidden={xsHidden}
        />
    )
}

BlogThumbnail.defaultProps = {
    shortCode: "",
    logoImgPath: "",
    lgWidth: 3,
    mdWidth: 6,
    smWidth: 6,
    smHidden: false,
    xsHidden: false,
}

BlogThumbnail.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    imgPath: PropTypes.string.isRequired,
    blogPostType: PropTypes.string.isRequired,
    shortCode: PropTypes.string,
    logoImgPath: PropTypes.string,
    lgWidth: PropTypes.number,
    mdWidth: PropTypes.number,
    smWidth: PropTypes.number,
    smHidden: PropTypes.bool,
    xsHidden: PropTypes.bool,
}

export default BlogThumbnail
