/* eslint-disable no-undef */
import React from "react"
import PropTypes from "prop-types"
import swal from "sweetalert"

import { connect } from "react-redux"

import { FormGroup, InputGroup, FormControl } from "react-bootstrap"

import { emailValidation } from "shared/imports/validationFunctions"

import * as actionCreators from "shared/marketing-website/action-creators"
import * as selectors from "shared/marketing-website/selectors"
import * as desktopSelectors from "quorum/static/frontend/marketing-website/selectors"

import {
    checkSubmission,
    scrollToRequestDemoSection
} from "quorum/static/frontend/marketing-website/constants/functions"
import { readCookieByKey } from "shared/imports/sharedHelperFunctions"

import "quorum/static/frontend/marketing-website/stylus/EmailRequest.styl"

const mapStateToProps = (state, props) => ({
    formValues: selectors.selectRequestBlogFormValues(state, props),
    validationDict: selectors.selectRequestBlogValidation(state, props),
    email: selectors.selectRequestBlogEmail(state, props),
    demoEmail: selectors.selectRequestDemoEmail(state, props),
    blogs: selectors.selectRequestBlogChoices(state, props),
    isUsingInternetExplorer10: desktopSelectors.selectIsUsingInternetExplorer10(state, props),
})

const EmailRequest = (props) => {
    const validate = (value) => {
        return value ? emailValidation(value) : null
    }

    const validateAndSubmit = () => {
        const data = {
            hs_context: JSON.stringify({
                hutk: readCookieByKey("hubspotutk"),
                pageUrl: window.location.href,
                pageName: document.title,
            }),
        }

        if (props.isDemoRequest) {
            if (!props.demoEmail) {
                swal({
                    title: "Please fill out the email field.",
                    icon: "warning",
                })
                return false
            }

            if (!validate(props.demoEmail)) {
                swal({
                    title: "Please input a valid email.",
                    icon: "warning",
                })
                return false
            }

            data.email = props.demoEmail

            // Google Analytics event tracking
            gtag("event", window.location.pathname, {
                event_category: "Demo",
                event_label: props.label,
            })

            props.submitForm(props.formUrl, data, props.formKey, false)

            props.isUsingInternetExplorer10 ? scrollToRequestDemoSection() : props.showDemoModal("Header")
        } else {
            const [allDefined, allValid] = checkSubmission(props.formValues, props.validationDict)

            if (!allDefined) {
                // swal to fill out form
                swal({
                    title: "Please fill out the email field.",
                    icon: "warning",
                })
                return false
            } else if (!allValid) {
                // swall invalid forms
                swal({
                    title: "Please input a valid email.",
                    icon: "warning",
                })
                return false
            }

            data.email = props.email

            // Google Analytics event tracking
            gtag("event", window.location.pathname, {
                event_category: "Newsletter",
                event_label: props.label,
            })

            props.submitForm(props.formUrl, data, props.formKey)

            swal({
                title: "Thank you!",
                text: "We'll reach out to you shortly.",
                icon: "success",
            })
        }
    }

    const getValue = () => {
        return props.isDemoRequest ? props.demoEmail : props.email
    }

    const handleChange = (e) => {
        props.updateForm(props.formKey, { email: e.target.value }, { email: validate(e.target.value) })
    }

    const getPlaceholder = () => {
        if (props.shortPlaceholder) {
            return "Enter your work email address..."
        }

        return props.isDemoRequest
            ? "Enter your work email address..."
            : "Enter your work email to receive our latest insights..."
    }

    const toggleBlog = (blogPostType) => {
        return () => {
            const blogPostTypeIndex = props.blogs.indexOf(blogPostType)

            const updatedBlogs = props.blogs.includes(blogPostType)
                ? props.blogs.delete(blogPostTypeIndex)
                : props.blogs.push(blogPostType)

            props.updateForm(props.formKey, { blogs: updatedBlogs })
        }
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            validateAndSubmit()
        }
    }

    return (
        <form>
            <FormGroup bsSize={props.bsSize}>
                <InputGroup>
                    <FormControl
                        type="text"
                        placeholder={getPlaceholder()}
                        onChange={handleChange}
                        value={getValue()}
                        onKeyPress={handleEnter}
                    />
                    <InputGroup.Text onClick={validateAndSubmit} className={props.isDemoRequest ? "" : "email-signup"}>
                        {props.isDemoRequest ? "Request Demo" : "Sign Up!"}
                    </InputGroup.Text>
                </InputGroup>
            </FormGroup>
        </form>
    )
}

EmailRequest.defaultProps = {
    formKey: "requestBlog",
    formUrl: "/submit_blog_request/",
    label: "",
    header: false,
    shortPlaceholder: false,
    isDemoRequest: false,
}

EmailRequest.propTypes = {
    blogs: PropTypes.object.isRequired,
    bsSize: PropTypes.string,
    email: PropTypes.string.isRequired,
    formKey: PropTypes.string,
    formUrl: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    header: PropTypes.bool,
    isUsingInternetExplorer10: PropTypes.bool.isRequired,
    isDemoRequest: PropTypes.bool,
    label: PropTypes.string,
    location: PropTypes.object,
    shortPlaceholder: PropTypes.bool,
    showDemoModal: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    validationDict: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, actionCreators)(EmailRequest)
